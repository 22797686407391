import React from "react";
import "../../../assets/css/realtors.css";
import ImagewithText from "../../../components/ImagewithText";
import ContactSection from "../../../components/ContactSection";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import PropertyCard from "../../../components/PropertyCard";
import img1 from "../../../assets/images/blog 01.jpg";
import img2 from "../../../assets/images/blog 02.jpg";
import img3 from "../../../assets/images/blog 04.jpg";
import img4 from "../../../assets/images/blog 05.jpg";
import img5 from "../../../assets/images/blog 06.jpg";
import img6 from "../../../assets/images/blog 07.jpg";

const Realtors = () => {
    const properties = [
        {
            id: 1,
            price: 850000,
            images: [img1, img5, img3],
            beds: 4,
            baths: 4.5,
            area: 2378,
            address: "36 Beach Side Dr Unit 36AP, Ocean City, MD 218",
        },
        {
            id: 2,
            price: 850000,
            images: [img6, img2, img4],
            beds: 4,
            baths: 4.5,
            area: 2378,
            address: "36 Beach Side Dr Unit 36AP, Ocean City, MD 218",
        },
        {
            id: 3,
            price: 850000,
            images: [img2, img6, img1],
            beds: 4,
            baths: 4.5,
            area: 2378,
            address: "36 Beach Side Dr Unit 36AP, Ocean City, MD 218",
        },
    ];

    return (
        <>
            <Header />

            <main className="Realtor_main_section">
                {/* Hero Section */}
                <section id="hero" className="bg-light text-center p-5">
                    <div className="container">
                        <h1 className="text-light ">Find Your Dream Home with Us</h1>
                        <p className="lead">
                            Expert Real Estate Services for Buyers and Sellers
                        </p>
                        <a href="#featured" className="btn btn-primary">
                            Explore Listings
                        </a>
                    </div>
                </section>

                {/* About Section */}
                <section id="about" className="py-5">
                    <div className="container">
                        <div className="row">
                            <ImagewithText
                                title="About Us"
                                content="We are professional realtors helping clients buy and sell properties
                  with ease. With years of experience, we understand the market and
                  offer personalized services to meet your needs."
                                imgSrc={img1}
                            />
                        </div>
                    </div>
                </section>

                {/* Services Section */}
                <section id="services" className="bg-light py-5">
                    <div className="container">
                        <h2 className="text-center mb-5">Our Services</h2>
                        <div className="row mt-4 d-flex flex-row">
                            {properties.map((property) => (
                                <PropertyCard
                                    key={property.id}
                                    id={property.id}
                                    price={property.price}
                                    images={property.images}
                                    beds={property.beds}
                                    baths={property.baths}
                                    area={property.area}
                                    address={property.address}
                                />
                            ))}
                        </div>
                    </div>
                </section>

                {/* Featured Listings Section */}
                <section id="" className="py-5 blog">
                    <div className="container mt-5">
                        <h2 className="text-center mb-5">Buying a Home</h2>
                        <div className="container">
                            <div className="row mt-4 d-flex flex-row">
                                {properties.map((property) => (
                                    <PropertyCard
                                        key={property.id}
                                        id={property.id}
                                        price={property.price}
                                        images={property.images}
                                        beds={property.beds}
                                        baths={property.baths}
                                        area={property.area}
                                        address={property.address}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <h2 className="text-center mb-5">Sell a Home</h2>
                        <div className="container">
                            <div className="row mt-4 d-flex flex-row">
                                {properties.map((property) => (
                                    <PropertyCard
                                        key={property.id}
                                        id={property.id}
                                        price={property.price}
                                        images={property.images}
                                        beds={property.beds}
                                        baths={property.baths}
                                        area={property.area}
                                        address={property.address}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </section>

                {/* Contact Section */}
                <section id="contact" className="py-5 bg-light">
                    <ContactSection />
                </section>
            </main>
            <Footer />
        </>
    );
};

export default Realtors;
