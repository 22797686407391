import React from 'react';
import Img01 from '../../assets/images/business-8788632_1280.jpg'

const ComprehensiveRealEstateSolutions = () => {
    return (
        <section className="mt-4 container">
            <div className="row p-4 bg-light rounded-lg shadow-lg">
                <div className="col-md-6 pe-md-4">
                    <h2 className="h4 fw-bold mb-3">Providing customers with comprehensive real estate solutions</h2>
                    <p className="text-muted mb-3">
                        Biznet (<a href="https://www.Biznet.com" className="text-primary">www.Biznet.com</a>) is a
                        Transaction Coordinator, we ensure that every real estate transaction progresses smoothly
                        from start to finish. Our job is to manage all the intricate details of the process,
                        including coordinating with agents, clients, lenders, title companies, and contractors. We
                        oversee timelines, handle the necessary paperwork, and ensure that all parties meet
                        deadlines. Whether it's buying, selling, or renting a home, we provide the support needed to
                        close the deal efficiently. Our expertise allows clients to navigate the complexities of
                        real estate transactions with confidence, knowing that every step is being carefully managed
                        for a seamless experience.
                    </p>
                </div>
                <div className="col-md-6">
                    <img src={Img01} alt="Real estate scene"
                         className="img-fluid rounded-lg shadow-md" />
                </div>
                <div className="d-flex align-items-center justify-content-between flex-md-row flex-column p-4 bg-light border-bottom">
                    <div className="d-flex flex-md-row flex-column gap-md-5 gap-2">
                        <h2 className="h4 fw-bold text-primary">Stay up to date</h2>
                        <p className="text-muted">Get the latest news and reports delivered right to your inbox.</p>
                    </div>
                    <button className="btn btn-investor text-light px-4 py-2 rounded">Sign up</button>
                </div>
            </div>
        </section>
    );
}

export default ComprehensiveRealEstateSolutions;
