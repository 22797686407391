import React from "react";
import { useParams, Link } from "react-router-dom"; // Import useParams and Link
import img1 from "../../..//assets/images/agent-sold.jpg";
import SellerHeader from "../../../components/SellerHeader";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

const AgentThanks = () => {
  const { id } = useParams(); // Get the jobId from the route parameter

  return (
    <>
      <Header />
      <SellerHeader />
      <main>
        <div className="container main-thanku">
          <div className="bg-light p-4 rounded shadow-sm mx-auto text-center">
            <h1 className="fs-2 fw-bold mb-3">Thank you, Muhammad!</h1>
            <img
              aria-hidden="true"
              alt="Person holding a sold sign in front of a house"
              src={img1}
              className="mb-3 img-fluid rounded"
              height={200}
              width={200}
            />
            <p className="text-muted mb-3">
              Thanks for your interest and sharing your experience with us!
              We've shared your information with our recruiting team. The next
              step is to complete your full application so we can learn a bit
              more about your qualifications. Please click "Apply" to continue.
            </p>
            <Link to={`/MultiStepForm/${id}`}>
              <button className="btn btn-primary">Apply Now</button>
            </Link>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default AgentThanks;
