import React from 'react';

const ServiceCards = ({ services }) => {
    return (
        <div className="row">
            {services.map((service, index) => (
                <div className="col-md-4" key={index}>
                    <div className="card shadow m-2">
                        <div className="card-body text-center">
                            <i className={`${service.icon} fs-1`} />
                            <h4 className="card-title mt-3">{service.title}</h4>
                            <p className="card-text">
                                {service.description}
                            </p>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ServiceCards;
