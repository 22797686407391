import React, { useState, useEffect } from "react";
import { FaInfoCircle } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    role_id: "", // Updated to use role_id
    password: "",
  });
  const [wantRealtor, setWantRealtor] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const toolTip = "A Transaction Coordinator is a real estate professional who manages the paperwork, deadlines, and communication involved in a real estate transaction.";
  const typeRealtor = "Types of Realtors: \n - Residential TC \n - Commercial TC \n - Wholesale / Investor / Sub2 TC";

  const [roles, setRoles] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch("https://api.biznetusa.com/api/get-roles");
        const data = await response.json();

        if (response.ok) {
          setRoles(data.roles || []);
        } else {
          throw new Error("Failed to fetch roles");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error("Unable to fetch roles.");
      }
    };

    fetchRoles();
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, role_id, password } = formData;

    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", name);
      formDataToSend.append("email", email);
      formDataToSend.append("user_role", role_id);
      formDataToSend.append("password", password);

      const response = await fetch("https://api.biznetusa.com/api/register", {
        method: "POST",
        body: formDataToSend,
      });

      const data = await response.json();
      console.log(response);

      if (!response.ok) {
        const errorMessage =
          data.errors.email || "Something went wrong during registration.";
        throw new Error(errorMessage);
      }

      const { user_id, user_role, access_token } = data;

      localStorage.setItem("user_id", user_id);
      localStorage.setItem("user_email", email);
      localStorage.setItem("user_role", user_role);
      localStorage.setItem("access_token", access_token);

      toast.success("Registration successful!");

      setFormData({
        name: "",
        email: "",
        role_id: "",
        password: "",
      });

      navigate("/Login", { state: { name: formData.name } });
    } catch (error) {
      console.error("Error during registration:", error);
      toast.error(error.message || "Failed to register. Please try again.");
    }
  };

  function onRealtorChange() {
    setWantRealtor(!wantRealtor);
    setShowModal(!showModal);
  }

  return (
    <>
      <main className="background_color_fixed">
        <ToastContainer />
        <div className="d-flex align-items-center justify-content-center min-vh-100">
          <div className="card body_color shadow-lg p-4" style={{ width: "26rem" }}>
            <a className="text-decoration-none" href="/">
              <h2 className="h4 fw-bold py-3 mb-3">Welcome to Biznet</h2>
            </a>
            <div className="mb-3 d-flex flex-row gap-5">
              <a className="text-decoration-none" href="/Login">
                <h3 className="h6 fw-semibold border-bottom border-2">
                  Sign In
                </h3>
              </a>
              <a className="text-decoration-none" href="/SignUp">
                <h3 className="h6 fw-semibold border-bottom border-2">
                  New Account
                </h3>
              </a>
            </div>

            <form className="mt-4" onSubmit={handleSubmit}>
              <label htmlFor="name" className="form-label">
                Name
              </label>
              <input
                className="form-control"
                type="text"
                id="name"
                placeholder="Enter your name"
                value={formData.name}
                onChange={handleChange}
                required
              />

              <label htmlFor="email" className="form-label mt-3">
                Email
              </label>
              <input
                className="form-control"
                type="email"
                id="email"
                placeholder="Enter email"
                value={formData.email}
                onChange={handleChange}
                required
              />

              <label htmlFor="role_id" className="form-label mt-3">
                User Role
              </label>
              <select
                className="form-control"
                id="role_id" // Update to role_id
                value={formData.role_id}
                onChange={handleChange}
                required
              >
                <option value="">Select a role</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.id}>
                    {role.role_name}
                  </option>
                ))}
              </select>

              {
                formData.role_id === '2' && (
                  <div>
                    <div className="form-check mt-3">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={wantRealtor}
                        onChange={onRealtorChange}
                        id="want-realtor"
                      />
                      <label className="form-label" htmlFor="want-realtor">
                        Do you want to mediate the transaction with a realtor?
                        <a className="ms-2" title={toolTip}><FaInfoCircle /></a>
                      </label>
                    </div>
                    {wantRealtor && (
                      <div>
                        <label className="form-label mt-3">
                          What are the types of Realtors?
                          <a className="ms-2" title={typeRealtor}><FaInfoCircle /></a>
                        </label>
                      </div>
                    )}

                    {/* Modal */}
                    {showModal && (
                      <div className="modal" tabIndex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title">Are you sure?</h5>
                            </div>
                            <div className="modal-body">
                              <p>Did you know that handling real estate transactions without a coordinator can lead to errors, delays, or missed deadlines? Consider using a Transaction Coordinator to ensure a smooth, error-free sale</p>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              }

              {
                formData.role_id === '15' && (
                  <div>
                    <label className="form-label mt-3" htmlFor="experience-level">
                      What is your Experience Level?
                    </label>
                    <select name="experience-level" id="experience-level" className="form-control">
                      <option value="Less than a year">Less than a year</option>
                      <option value="1+ Year">1+ Year</option>
                      <option value="2+ Year">2+ Year</option>
                      <option value="5+ Year">5+ Year</option>
                    </select>

                    <label htmlFor="specialize" className="form-label mt-3">
                      State the type of transactions you specialize in
                    </label>
                    <select name="specialize" id="specialize" className="form-select">
                      <option value="Residential">Residential</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Industrial">Industrial</option>
                    </select>

                    <label htmlFor="geographical" className="form-label mt-3">
                      Geographical Area:
                    </label>
                    <select name="geographical" id="geographical" className="form-select">
                      <option value="Region">Region</option>
                    </select>
                  </div>
                )
              }

              <label htmlFor="password" className="form-label mt-3">
                Password
              </label>
              <input
                className="form-control"
                type="password"
                id="password"
                placeholder="Create password"
                value={formData.password}
                onChange={handleChange}
                required
              />

              <small className="form-text d-flex flex-column py-3 px-3">
                <span>At least 8 characters.</span>
                <span>Mix of letters and numbers.</span>
                <span>At least 1 special character.</span>
                <span>At least 1 lowercase letter.</span>
                <span>At least 1 uppercase letter.</span>
              </small>

              <button type="submit" className="btn btn-primary mt-4 w-100">
                Submit
              </button>

              <p className="text-center mt-3">
                By submitting, I accept Biznet{" "}
                <a href="#" className="text-decoration-underline">
                  terms of use
                </a>
                .
              </p>
            </form>

            <div className="my-4 border-top text-center pt-3">
              <p>Or connect with:</p>
              <div className="d-flex justify-content-center flex-column gap-3 mt-2">
                <button className="btn btn-outline-secondary d-flex align-items-center">
                  <i className="fab fa-apple me-2" />
                  Continue with Apple
                </button>
                <button className="btn btn-outline-secondary d-flex align-items-center">
                  <i className="fab fa-facebook-f me-2" />
                  Continue with Facebook
                </button>
                <button className="btn btn-outline-secondary d-flex align-items-center">
                  <i className="fab fa-google me-2" />
                  Continue with Google
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default SignUp;

