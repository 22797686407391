import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Form, Button, Image, Tab, Nav, Alert } from "react-bootstrap";
import TimezoneSelect from "react-timezone-select"; // Importing the timezone select component
import "./AccountSettingCandidate.css";
import img1 from "../../../assets/images/img_agent_career/salman-saqib-WaC-JFfF21M-unsplash.jpg";
import SellerAgentHeader from "../../../components/SellerAgentHeader";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

const AccountSettingCandidate = () => {
  const [user, setUser] = useState({
    id: "",
    name: "",
    email: "",
    phone_number: "",
    image: "",
    timezone: "",
    connect_with_facebook: "",
    connect_with_google: "",
  });
  const [profileImage, setProfileImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone); // Default to user's current timezone

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      axios
        .get(`https://api.biznetusa.com/api/user-profile/${userId}`)
        .then((response) => {
          const userData = response.data.allusers;
          setUser({
            id: userData.id,
            name: userData.name,
            email: userData.email,
            phone_number: userData.phone_number,
            image: userData.image,
            timezone: userData.timezone || selectedTimezone,
            connect_with_facebook: userData.connect_with_facebook,
            connect_with_google: userData.connect_with_google,
          });
          setSelectedTimezone(userData.timezone || selectedTimezone);
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
        });
    }
  }, [selectedTimezone]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
  };

  const handleProfileUpdate = () => {
    const userId = localStorage.getItem("user_id");
    const formData = new FormData();
    formData.append("name", user.name);
    formData.append("email", user.email);
    formData.append("phone_number", user.phone_number);
    formData.append("timezone", selectedTimezone);
    console.log("form Data:", formData);
    if (profileImage) {
      formData.append("image", profileImage);
    }

    axios
      .put(`https://api.biznetusa.com/api/profile-update/${userId}`, formData)
      .then(() => {
        alert("Profile updated successfully!");
        setErrors({});
      })
      .catch((error) => {
        if (error.response && error.response.data.errors) {
          setErrors(error.response.data.errors);
        } else {
          console.error("Error updating profile:", error);
          alert("Failed to update profile.");
        }
      });
  };

  return (
    <>
      <Header />
      <SellerAgentHeader />
      <Container fluid className="account-setting-candidate-home py-4">
        <Image src={img1} alt="Header" className="w-100 mb-4" />
        <Row>
          <Col>
            <h4 className="py-4">Account Settings</h4>
            <Tab.Container defaultActiveKey="changeEmail">
              <Row>
                <Col md={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="changeEmail">Change Email</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="profile">Profile</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="settings">Settings</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={9}>
                  <Tab.Content>
                    {/* Change Email Section */}
                    <Tab.Pane eventKey="changeEmail">
                      <h5>Change Email</h5>
                      <Form>
                        <Form.Group as={Row} className="mb-3">
                          <Form.Label column sm={2}>
                            Current Email
                          </Form.Label>
                          <Col sm={10}>
                            <Form.Control
                              plaintext
                              readOnly
                              defaultValue={user.email}
                              className="form-control-plaintext"
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group controlId="newEmail" className="mb-3">
                          <Form.Label>New Email address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="name@example.com"
                            name="email"
                            value={user.email}
                            onChange={handleInputChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email[0]}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="dateInput" className="mb-3">
                          <Form.Label>Date</Form.Label>
                          <Form.Control type="date" defaultValue="2024-09-30" />
                        </Form.Group>
                      </Form>
                    </Tab.Pane>

                    {/* Profile Section */}
                    <Tab.Pane eventKey="profile">
                      <h5>Profile Information</h5>
                      {errors.name && <Alert variant="danger">{errors.name[0]}</Alert>}
                      <Form>
                        <Form.Group controlId="formName" className="mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            value={user.name}
                            onChange={handleInputChange}
                            isInvalid={!!errors.name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.name && errors.name[0]}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formEmail" className="mb-3">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={user.email}
                            onChange={handleInputChange}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email && errors.email[0]}
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group controlId="formPhoneNumber" className="mb-3">
                          <Form.Label>Phone Number</Form.Label>
                          <Form.Control
                            type="text"
                            name="phone_number"
                            value={user.phone_number}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                        <Form.Group controlId="formProfileImage" className="mb-3">
                          <Form.Label>Profile Image</Form.Label>
                          <Form.Control type="file" onChange={handleImageChange} />
                          {user.image && (
                            <div className="mt-3">
                              <Image
                                src={`https://api.biznetusa.com/uploads/users/${user.image}`}
                                roundedCircle
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          )}
                        </Form.Group>
                        <Form.Group controlId="formTimezone" className="mb-3">
                          <Form.Label>Select Timezone</Form.Label>
                          <TimezoneSelect
                            value={selectedTimezone}
                            onChange={setSelectedTimezone}
                          />
                        </Form.Group>
                        <Button variant="primary" onClick={handleProfileUpdate}>
                          Save
                        </Button>
                      </Form>
                    </Tab.Pane>

                    {/* Settings Section */}
                    <Tab.Pane eventKey="settings">
                      <h5>Account Settings</h5>
                      <Form.Group className="d-flex align-items-center mb-3">
                        <Form.Label className="me-3">Notification Preferences:</Form.Label>
                        <Form.Check
                          type="switch"
                          id="flexSwitchCheckDefault"
                          label="OFF/ON"
                        />
                      </Form.Group>
                      <Form.Group controlId="privacySelect" className="mb-3">
                        <Form.Label>Privacy Settings</Form.Label>
                        <Form.Select aria-label="Default select example">
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                          <option value="disable">Disable</option>
                        </Form.Select>
                      </Form.Group>
                      <Form.Group controlId="languageSelect" className="mb-3">
                        <Form.Label>Language</Form.Label>
                        <Form.Select>
                          <option>English (US)</option>
                          <option>English (UK)</option>
                          <option>Spanish</option>
                          <option>French</option>
                          <option>German</option>
                        </Form.Select>
                      </Form.Group>
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
            <div className="text-end mt-3">
              <Button variant="primary" className="text-white fw-bold px-5 py-2" onClick={handleProfileUpdate}>
                Save
              </Button>
            </div>
          </Col>
        </Row>
        <section>
          <Row className="text-center py-5">
            <h5>Follow Us</h5>
            <div className="d-flex gap-3 justify-content-center py-3">
              <a href="#" className="social-icon text-decoration-none">
                <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 40, height: 40 }}>
                  <i className="fa-brands fa-linkedin-in" />
                </span>
              </a>
              <a href="#" className="social-icon text-decoration-none">
                <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 40, height: 40 }}>
                  <i className="fa-brands fa-twitter" />
                </span>
              </a>
              <a href="#" className="social-icon text-decoration-none">
                <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 40, height: 40 }}>
                  <i className="fa-brands fa-facebook-f" />
                </span>
              </a>
              <a href="#" className="social-icon text-decoration-none">
                <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 40, height: 40 }}>
                  <i className="fa-brands fa-youtube" />
                </span>
              </a>
              <a href="#" className="social-icon text-decoration-none">
                <span className="d-flex justify-content-center align-items-center rounded-circle" style={{ width: 40, height: 40 }}>
                  <i className="fa-brands fa-discord" />
                </span>
              </a>
            </div>
          </Row>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default AccountSettingCandidate;
