import React from "react";

const Testimonial = () => {
    return (
        <>
            <section className="py-5  bg-light">
                <div className="container">
                    <h2 className="section-title  text-center">
                        What Our Clients Say
                    </h2>
                    <div
                        id="testimonialCarousel"
                        className="carousel slide"
                        data-bs-ride="carousel"
                    >
                        <div className="carousel-inner">
                            {/* Testimonial 1 */}
                            <div className="carousel-item active">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 card p-3 ">
                                        <p className="testimonial">
                                            "Biznet helped us find our dream home with exceptional
                                            service. Their professionalism, attention to detail, and
                                            dedication were truly outstanding. We couldn’t have asked for
                                            a better real estate partner. Their professionalism, attention
                                            to detail, and dedication were truly outstanding. We couldn’t
                                            have asked for a better real estate partner." – Sarah L.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Testimonial 2 */}
                            <div className="carousel-item">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 card p-3">
                                        <p className="testimonial">
                                            "We sold our property in no time, thanks to the expertise of
                                            Biznet’s team. Their market knowledge and proactive approach
                                            made the process smooth and stress-free. Highly recommend!"
                                            Their professionalism, attention to detail, and dedication
                                            were truly outstanding. We couldn’t have asked for a better
                                            real estate partner.– Mark D.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            {/* Testimonial 3 (optional) */}
                            <div className="carousel-item">
                                <div className="row justify-content-center">
                                    <div className="col-lg-6 card p-3">
                                        <p className="testimonial">
                                            "Biznet exceeded our expectations in every aspect. From the
                                            initial consultation to closing the deal, they were attentive,
                                            responsive, and highly professional. Our investment process
                                            was seamless."Their professionalism, attention to detail, and
                                            dedication were truly outstanding. We couldn’t have asked for
                                            a better real estate partner. – Emily W.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Carousel controls */}
                        <button
                            className="carousel-control-prev "
                            type="button"
                            data-bs-target="#testimonialCarousel"
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon " aria-hidden="true" />
                            <span className="visually-hidden ">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next  "
                            type="button"
                            data-bs-target="#testimonialCarousel"
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true" />
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </section>
        </>
    );

};
export default Testimonial;