import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareListingModal from "../SharePopup";
import FavoriteButton from "../FavoriteButton";
import { useNavigate } from "react-router-dom";

const MainCards = () => {
    const [shownProperties, setShownProperties] = useState([]);
    const [activeSection, setActiveSection] = useState("all");
    const [carouselIndex, setCarouselIndex] = useState({});
    const [properties, setProperties] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showShareModal, setShowShareModal] = useState(false);
    const [selectedProductSlug, setSelectedProductSlug] = useState(null);
    const [showMap, setShowMap] = useState({});
    const [userId, setUserId] = useState(null);

    const numberOfProperties = 3;
    const navigate = useNavigate();

    // Retrieve userId from localStorage
    useEffect(() => {
        const storedUserId = localStorage.getItem("user_id");
        if (storedUserId) {
            setUserId(storedUserId);
            console.log("Retrieved userId from localStorage:", storedUserId);
        } else {
            console.error("User ID not found in localStorage.");
            navigate("/login");
        }
    }, [navigate]);

    const increaseShown = () => {
        if (shownProperties.length + numberOfProperties < properties.length) {
            setShownProperties(properties.slice(0, shownProperties.length + numberOfProperties));
        } else if (shownProperties.length === properties.length) {
            setShownProperties(properties.slice(0, numberOfProperties));
        } else {
            setShownProperties(properties.slice(0, properties.length));
        }
    }

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const [productResponse, productImageResponse] = await Promise.all([
                    fetch("https://api.biznetusa.com/api/get-products"),
                    fetch("https://api.biznetusa.com/api/get-productimages"),
                ]);

                const productData = await productResponse.json();
                const productImageData = await productImageResponse.json();

                if (productData.status === 200 && productImageData.status === 200) {
                    const mergedProperties = productData.products.map((product) => {
                        const images = productImageData.products
                            .filter((image) => image.pd_id === product.id)
                            .map((img) => img.image);

                        return { ...product, images };
                    });

                    setProperties(mergedProperties);
                    setShownProperties(mergedProperties.slice(0, 3));
                    console.log("properties", shownProperties);
                } else {
                    throw new Error("Failed to load product or image data");
                }
            } catch (err) {
                setError(err.message || "An unexpected error occurred.");
            } finally {
                setLoading(false);
            }
        };

        fetchProductData();
    }, []);

    const handleSelect = (selectedIndex, propertyId) => {
        setCarouselIndex({ ...carouselIndex, [propertyId]: selectedIndex });
    };

    const toggleMapView = (propertyId) => {
        setShowMap((prevState) => ({
            ...prevState,
            [propertyId]: !prevState[propertyId],
        }));
    };

    const showSection = (section) => {
        setActiveSection(section);
    };

    const openShareModal = (productSlug) => {
        setSelectedProductSlug(productSlug);
        setShowShareModal(true);
    };

    const closeShareModal = () => {
        setShowShareModal(false);
        setSelectedProductSlug(null);
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="container py-4">
            <ToastContainer />
            <h1 className="h2 fw-bold">Feed</h1>
            <div className="d-flex feed_all_button d-flex flex-wrap gap-2 mt-3">
                {[
                    "all",
                    "new",
                    "favorites",
                    "price-change",
                    "open-house",
                    "insights",
                    "sold",
                    "status-change",
                ].map((section) => (
                    <button
                        key={section}
                        className={`btn ${activeSection === section ? "btn-primary" : "btn-outline-primary"
                            }`}
                        onClick={() => showSection(section)}
                    >
                        {section.replace("-", " ").toUpperCase()}
                    </button>
                ))}
            </div>

            {activeSection === "all" && (
                <div id="all" className="content-section">
                    <div className="container py-4">
                        <h2 className="h2 fw-bold">All Property Listings</h2>
                        <div className="row">
                            {shownProperties.map((property) => (
                                <div
                                    className="col-sm-6 home_cards col-lg-4 mb-4"
                                    key={property.id}
                                >
                                    <div className="card position-relative">
                                        {!showMap[property.id] ? (
                                            <div
                                                id={`carouselProperty${property.id}`}
                                                className="carousel slide"
                                                data-bs-ride="carousel"
                                            >
                                                <div className="carousel-inner position-relative">
                                                    {property.images.length > 0 ? (
                                                        property.images.map((image, index) => (
                                                            <div
                                                                className={`carousel-item position-relative ${index === (carouselIndex[property.id] || 0)
                                                                    ? "active"
                                                                    : ""
                                                                    }`}
                                                                key={index}
                                                            >
                                                                <div className="position-absolute end-0 bottom-0 mx-4 mb-3 px-2 py-1 bg-dark opacity-75 rounded-circle">
                                                                    {/* image toggle */}
                                                                    <i
                                                                        className={`fa fa-map fs-6 text-white ${showMap[property.id]
                                                                            ? "text-primary"
                                                                            : "text-secondary"
                                                                            }`}
                                                                        role="button"
                                                                        aria-label="Show Map"
                                                                        onClick={() => toggleMapView(property.id)}
                                                                    />
                                                                </div>
                                                                <img
                                                                    onClick={() => navigate(`/ProductDetail/${property.id}`)}  // Navigate to ProductDetail with p_id
                                                                    src={`https://api.biznetusa.com/uploads/products/${image}`}
                                                                    alt={`Property ${property.id} Image ${index + 1

                                                                        }`}
                                                                    className="d-block w-100"
                                                                    style={{
                                                                        height: "250px",
                                                                        objectFit: "cover",
                                                                        cursor: "pointer"
                                                                    }}
                                                                />
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="carousel-item active">
                                                            <img
                                                                src="/placeholder.jpg"
                                                                alt="Placeholder"
                                                                className="d-block w-100"
                                                                style={{ height: "250px", objectFit: "cover" }}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                {property.images.length > 1 && (
                                                    <>
                                                        <button
                                                            className="carousel-control-prev"
                                                            type="button"
                                                            onClick={() =>
                                                                handleSelect(
                                                                    (carouselIndex[property.id] || 0) === 0
                                                                        ? property.images.length - 1
                                                                        : (carouselIndex[property.id] || 0) - 1,
                                                                    property.id
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                className="carousel-control-prev-icon"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="visually-hidden">Previous</span>
                                                        </button>
                                                        <button
                                                            className="carousel-control-next"
                                                            type="button"
                                                            onClick={() =>
                                                                handleSelect(
                                                                    (carouselIndex[property.id] || 0) + 1 >=
                                                                        property.images.length
                                                                        ? 0
                                                                        : (carouselIndex[property.id] || 0) + 1,
                                                                    property.id
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                className="carousel-control-next-icon"
                                                                aria-hidden="true"
                                                            />
                                                            <span className="visually-hidden">Next</span>
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                className="map-container position-relative"
                                                style={{ height: "250px" }}
                                            >
                                                <div className="position-absolute end-0 bottom-0 mx-4 mb-3 px-2 py-1 bg-dark opacity-75 rounded-circle">
                                                    <i
                                                        className={`fa fa-image  fs-6 text-white ${!showMap[property.id]
                                                            ? "text-primary"
                                                            : "text-secondary"
                                                            }`}
                                                        role="button"
                                                        aria-label="Show Images"
                                                        onClick={() => toggleMapView(property.id)}
                                                    />
                                                </div>
                                                <iframe
                                                    width="100%"
                                                    height="100%"
                                                    frameBorder="0"
                                                    style={{ border: 0 }}
                                                    src={property.map_url} // The `map_url` now contains the properly formatted Google Maps embed link
                                                    allowFullScreen
                                                />
                                            </div>
                                        )}
                                        <div className="card-body">
                                            <div className="d-flex justify-content-between">
                                                <h3 className="h5 fw-bold text-dark">
                                                    {property.price}
                                                </h3>
                                                <div>
                                                    <i
                                                        className="fa-solid fa-share"
                                                        role="button"
                                                        aria-label="Share"
                                                        onClick={() => openShareModal(property.slug)}
                                                    />
                                                    <FavoriteButton
                                                        userId={userId}
                                                        productId={property.id}
                                                    />
                                                </div>
                                            </div>
                                            <p className="small text-dark">{property.location}</p>
                                        </div>



                                    </div>
                                </div>
                            ))}
                            <button className="btn show_more_layoutSet mt-4 mx-auto" onClick={increaseShown}>
                                {shownProperties.length === properties.length ? "Show Less" : "Show more"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <ShareListingModal
                isOpen={showShareModal}
                onClose={closeShareModal}
                productSlug={selectedProductSlug}
            />
        </div>
    );
};

export default MainCards;
