import React from "react";
import ProductAllCards from "../ProductAllCards";
import Map from "../Map";

const Split = () => {
    return (
        <>
            <div id="Split" className="container-fluid ">
                <div className="row ">
                    <div className="col-lg-7 Split">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 col-md-6 mt-4">
                                <ProductAllCards />
                            </div>
                        </div>

                    </div>
                    <div className="col-lg-5">
                        <Map />
                    </div>
                </div>
            </div>


        </>
    );
};
export default Split;
