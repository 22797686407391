import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const ProductAllCards = () => {
    const [products, setProducts] = useState([]);
    const [imagePath, setImagePath] = useState("");

    const location = useLocation();
    const cityId = new URLSearchParams(location.search).get("cityId");
    const navigate = useNavigate(); // Hook to navigate to another page
    const fetchCalled = useRef(false); // Prevent multiple API calls

    // Fetch product data
    useEffect(() => {
        const fetchProducts = async () => {
            if (!cityId || fetchCalled.current) return; // Prevent fetching if no cityId or already fetched
            fetchCalled.current = true; // Mark fetch as called

            try {
                const response = await axios.get(`https://api.biznetusa.com/api/get-filterbyhome/${cityId}`);
                console.log("API Response:", response.data);

                if (response.data.products) {
                    // Deduplicate products
                    const uniqueProducts = response.data.products.reduce((acc, current) => {
                        const isDuplicate = acc.some(product => product.id === current.id);
                        if (!isDuplicate) {
                            acc.push(current);
                        }
                        return acc;
                    }, []);

                    setProducts(uniqueProducts); // Set the products array
                    setImagePath(response.data.imagePath); // Set the image path
                }
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [cityId]);

    // Function to handle tour request button click
    const handleRequestTourClick = () => {
        navigate("/RequestShowing"); // Navigate to the RequestShowing page
    };

    return (
        <>
            {products.map((product, index) => (
                <div className="home_cards mb-4" key={product.id}>
                    <div className="card">
                        {/* Start of Carousel */}
                        <div
                            id={`carouselProperty${index}`}
                            className="carousel slide"
                            data-bs-ride="carousel"
                        >
                            <div className="carousel-inner">
                                {product.images && product.images.length > 0 ? (
                                    product.images.map((image, imgIndex) => (
                                        <div
                                            key={imgIndex}
                                            className={`carousel-item ${imgIndex === 0 ? "active" : ""}`}
                                        >
                                            <img
                                                onClick={() => { navigate(`/ProductDetail/${product.id}`); }}
                                                src={`${imagePath}${image.image}`
                                                }
                                                alt={`Property ${product.title} - Image ${imgIndex + 1}`}
                                                className="d-block w-100"
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <div className="carousel-item active">
                                        <img
                                            src="https://via.placeholder.com/400x300?text=No+Image+Available"
                                            alt="No Image Available"
                                            className="d-block w-100"
                                        />
                                    </div>
                                )}
                            </div>
                            <button
                                className="carousel-control-prev"
                                type="button"
                                data-bs-target={`#carouselProperty${index}`}
                                data-bs-slide="prev"
                            >
                                <span className="carousel-control-prev-icon" aria-hidden="true" />
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button
                                className="carousel-control-next"
                                type="button"
                                data-bs-target={`#carouselProperty${index}`}
                                data-bs-slide="next"
                            >
                                <span className="carousel-control-next-icon" aria-hidden="true" />
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        {/* End of Carousel */}
                        <div className="card-body">
                            <h3 className="h5 fw-bold text-decoration-none">${product.price}</h3>
                            <p>{product.location || "Location not available"}</p>
                            <p>{product.desc || "No description available"}</p>
                            <button
                                type="button"
                                className="btn"
                                onClick={handleRequestTourClick}
                            >
                                Request a Tour
                            </button>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default ProductAllCards;
