import React from "react";
import FAQs from "../../../components/FAQs";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

const FAQsPage = () => {
  const faqData = [
    {
      question: "What is RENT?",
      answer:
        "RENT is a platform that helps you find the best deals on real estate properties. We provide a curated list of properties that fit your criteria, negotiate the best deals on your behalf, and offer expert guidance throughout the process.",
    },
    {
      question: "How do I get started?",
      answer:
        'To get started, simply click on the "Get Started" button on our homepage and fill out the form with your details. Our team will then contact you to discuss your requirements and provide you with a personalized list of properties.',
    },
    {
      question: "What kind of properties do you offer?",
      answer:
        "We offer a wide range of properties, including luxury villas, modern apartments, cozy condos, and more. Our properties are carefully selected to ensure that they meet our high standards of quality and value.",
    },
    {
      question: "How do you negotiate the best deals?",
      answer:
        "Our team of experts has years of experience in negotiating the best deals on behalf of our clients. We use our knowledge of the market and our relationships with property owners to secure the best possible prices for our clients.",
    },
    {
      question: "What kind of support do you offer?",
      answer:
        "We offer expert guidance throughout the entire process, from start to finish. Our team is available to answer any questions you may have and provide support every step of the way.",
    },
    {
      question: "How long does the process take?",
      answer:
        "The length of the process varies depending on your specific requirements and the complexity of the deal. However, we strive to complete the process as quickly and efficiently as possible while ensuring that our high standards of quality are met.",
    },
    {
      question: "Do you offer flexible payment plans?",
      answer:
        "Yes, we offer flexible payment plans and options to fit your budget and needs. We understand that every client is unique, and we work with you to find a payment plan that works for you.",
    },
    {
      question: "Can I cancel my subscription at any time?",
      answer:
        "Yes, you can cancel your subscription at any time. We offer a flexible cancellation policy that allows you to cancel your subscription with no penalties or fees.",
    },
    {
      question: "How do I contact your team?",
      answer:
        'You can contact our team by clicking on the "Contact Us" button on our website or by calling us at [phone number]. We are available to answer any questions you may have and provide support every step of the way.',
    },
    {
      question: "Is my personal information secure?",
      answer:
        "Yes, your personal information is completely secure. We use industry-standard security measures to protect your data and ensure that it is kept confidential.",
    },
  ];

  return (
    <>
      <Header />
      <main>
        <div className="faq-page">
          <div className="container my-5">
            <h2 class="text-center">Frequently Asked Questions</h2>
            <FAQs faqs={faqData} />
          </div>
          <div className="container my-5">
            <div className="row">
              <div className="col-lg-5 col-md-12 mb-4">
                <h2>What's clients say about us</h2>
                <p>
                  We help clients buy and sell homes, yes, but it's about far
                  more than closing deals for us.
                </p>
                <a href="#" className="btn btn-outline-faq">
                  Explore all →
                </a>
              </div>
              <div className="col-lg-7 col-md-12">
                <div
                  id="testimonialCarousel"
                  className="carousel shadow slide"
                  data-bs-ride="carousel"
                  data-bs-interval={5000}
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="card p-4">
                        <p>
                          Amazing customer support so far, working well with a
                          theme that's so easily customizable and great to use.
                          Thanks and 5 stars!
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            src="https://randomuser.me/api/portraits/women/65.jpg"
                            alt="Maggie Strickland"
                            className="rounded-circle me-3"
                            style={{ width: 50 }}
                          />
                          <div>
                            <strong>Maggie Strickland</strong>
                            <p className="mb-0 text-muted">Manchester</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card p-4">
                        <p>
                          Working with @GrandHome is like having a family member
                          who can fix everything. They know what you need,
                          exactly when you need it.
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            src="https://randomuser.me/api/portraits/women/66.jpg"
                            alt="Maggie Strickland"
                            className="rounded-circle me-3"
                            style={{ width: 50 }}
                          />
                          <div>
                            <strong>Maggie Strickland</strong>
                            <p className="mb-0 text-muted">San Diego</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="card p-4">
                        <p>
                          Very good and fast support. Solved all my problems
                          within a week. Excited to see more themes they make!
                        </p>
                        <div className="d-flex align-items-center">
                          <img
                            src="https://randomuser.me/api/portraits/men/65.jpg"
                            alt="Dollie Horton"
                            className="rounded-circle me-3"
                            style={{ width: 50 }}
                          />
                          <div>
                            <strong>Dollie Horton</strong>
                            <p className="mb-0 text-muted">San Diego</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default FAQsPage;
