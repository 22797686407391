const ContactCard = ({ title, content, linkText }) => {
    return (
      <div className="col-12 col-md-4">
        <div className="p-3 card h-100 rounded shadow-sm">
          <h3 className="h5 fw-bold">{title}</h3>
          {content.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
          {linkText && <a href="javascript:void(0)" className="text-primary">{linkText}</a>}
        </div>
      </div>
    );
  };
  
  export default ContactCard;
  