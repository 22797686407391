import React from 'react';
import './CoreValueCard.css'

const CoreValueCard = ({ title, text }) => {
  return (
    <div className="col-md-4  my-4">
      <div className="card card-CoreValueCard p-3 h-100">
        <div className="card-body card-CoreValueCard-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{text}</p>
        </div>
      </div>
    </div>
  );
};

export default CoreValueCard;
