import React from "react";
import MonthlyPerformanceChart from "../../components/MonthlyPerformanceChart";
import YearlyPerformanceChart from "../../components/YearlyPerformanceChart";
import InvestorHeader from "../../components/InvestorHeader";
import Header from "../../components/header";
import Footer from "../../components/Footer";

const AnalyticsPerformace = () => {
    return (
        <>
            <Header />
            <InvestorHeader />
            <div className="container my-4">
                <div className="row">
                    <div className="col-lg-6">
                        <h3 className="text-center my-3">Monthly Performance Chart</h3>
                        <MonthlyPerformanceChart />
                    </div>
                    <div className="col-lg-6">
                        <h3 className="text-center my-3">Yearly Performance Chart</h3>
                        <YearlyPerformanceChart />
                    </div>
                </div>
            </div>
            <Footer/>

        </>
    )
}

export default AnalyticsPerformace;