import React, { useState } from "react";
import './RealEstateTabs.css';

const RealEstateTabs = () => {
  const [activeTab, setActiveTab] = useState("buy");
  const [searchTerm, setSearchTerm] = useState(""); // State for storing search term

  // Function to handle search and send data to the API
  const handleSearch = async () => {
    const user_id = localStorage.getItem("user_id"); // Retrieve user_id from localStorage
    if (!user_id) {
      console.error("User ID not found in localStorage");
      return;
    }

    // Make sure there's a search term to submit
    if (!searchTerm.trim()) {
      console.error("Search term is empty");
      return;
    }

    // Data to send to the API
    const searchData = {
      user_id: user_id,
      title: searchTerm
    };

    try {
      const response = await fetch('https://api.biznetusa.com/api/store-user-search', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(searchData)
      });

      const result = await response.json();
      if (response.ok) {
        console.log("Search data stored successfully:", result);
      } else {
        console.error("Error storing search data:", result);
      }
    } catch (error) {
      console.error("Error in search request:", error);
    }
  };

  return (
    <div className="col-lg-5 pt-4 mb-4">
      <div className="container steps_buttons_real justify-content-center d-flex align-content-center flex-column">
        <h1 className="text-start fs-2 fw-bold my-4">Find the right home at the right price</h1>

        {/* Navigation Tabs */}
        <ul className="nav nav-tabs mb-3 gap-2 flex-wrap" id="realEstateTabs" role="tablist">
          <li className="nav-item " role="presentation">
            <button
              className={`nav-link fs-15 ${activeTab === "buy" ? "active" : ""}`}
              onClick={() => setActiveTab("buy")}
            >
              Buy
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link fs-15 ${activeTab === "rent" ? "active" : ""}`}
              onClick={() => setActiveTab("rent")}
            >
              Rent
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "sell" ? "active" : ""}`}
              onClick={() => setActiveTab("sell")}
            >
              Sell
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "mortgage" ? "active" : ""}`}
              onClick={() => setActiveTab("mortgage")}
            >
              Mortgage
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`nav-link ${activeTab === "estimate" ? "active" : ""}`}
              onClick={() => setActiveTab("estimate")}
            >
              Home Estimate
            </button>
          </li>
        </ul>

        {/* Tab Content */}
        <div className="tab-content" id="realEstateTabContent">
          {/* Buy Content */}
          {activeTab === "buy" && (
            <div className="tab-pane fade show active" id="buy" role="tabpanel">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="City, Address, School, Agent, ZIP"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
                <button type="button" onClick={handleSearch}>
                  <i className="fa-solid fa-magnifying-glass" />
                </button>
              </div>
            </div>
          )}

          {/* Rent Content */}
          {activeTab === "rent" && (
            <div className="tab-pane fade show active" id="rent" role="tabpanel">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter location for renting"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
                <button type="button" onClick={handleSearch}>
                  <i className="fa-solid fa-magnifying-glass" />
                </button>
              </div>
            </div>
          )}

          {/* Sell Content */}
          {activeTab === "sell" && (
            <div className="tab-pane fade show active" id="sell" role="tabpanel">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sell your property"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
                <button type="button" onClick={handleSearch}>
                  <span>Get Started</span>
                </button>
              </div>
            </div>
          )}

          {/* Mortgage Content */}
          {activeTab === "mortgage" && (
            <div className="tab-pane fade show active" id="mortgage" role="tabpanel">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Mortgage options"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
                <button type="button" onClick={handleSearch}>
                  <span>Next</span>
                </button>
              </div>
            </div>
          )}

          {/* Home Estimate Content */}
          {activeTab === "estimate" && (
            <div className="tab-pane fade show active" id="estimate" role="tabpanel">
              <div className="search-bar">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Get a home estimate"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)} // Update search term
                />
                <button type="button" onClick={handleSearch}>
                  <i className="fa-solid fa-magnifying-glass" />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RealEstateTabs;
