import React from "react";
import FAQs from "../../components/FAQs";
import "./Contact.css";
import Header from "../../components/header";
import Footer from "../../components/Footer";


const Contact = () => {


    const faqData = [
        {
            question: "What is BizNet Real Estate?",
            answer: `BizNet Real Estate is a platform dedicated to helping you find and secure
          the best real estate deals. We offer a comprehensive service, including property search,
          market analysis, and negotiation, to ensure you get the best possible deal on your property investment.`,
        },
        {
            question: "How do I get started with BizNet Real Estate?",
            answer: `To get started, click on the "Get Started" button on our homepage and fill out the form
          with your details. Our team will then contact you to discuss your requirements and guide you through the process
          of finding your ideal property.`,
        },
        {
            question: "What types of properties does BizNet Real Estate offer?",
            answer: `We offer a diverse range of properties, including luxury homes, modern apartments,
          and investment properties. Our portfolio is curated to ensure you have access to high-quality options
          that meet your needs.`,
        },
        {
            question: "How does BizNet Real Estate negotiate deals?",
            answer: `Our team leverages extensive market knowledge and strong relationships with property owners 
          to negotiate the best possible deals for our clients. We focus on securing favorable terms and ensuring 
          your investment is worthwhile.`,
        },
        {
            question: "What support does BizNet Real Estate provide?",
            answer: `We provide full support throughout the property buying or selling process. Our experts are available 
          to answer questions, provide advice, and assist with every aspect of your real estate transaction.`,
        },
        {
            question: "How long does it take to complete a deal with BizNet Real Estate?",
            answer: `The time frame for completing a real estate deal varies based on the specifics of the transaction. 
          We work diligently to expedite the process while maintaining high standards of service and ensuring a smooth experience.`,
        },
        {
            question: "Are flexible payment plans available?",
            answer: `Yes, BizNet Real Estate offers flexible payment plans to accommodate various budgets and financial situations. 
          We are committed to working with you to find a payment solution that meets your needs.`,
        },
        {
            question: "Can I cancel my subscription at any time?",
            answer: `Yes, you can cancel your subscription at any time. We offer a flexible cancellation policy to ensure 
          you have control over your subscription without facing any penalties.`,
        },
        {
            question: "How can I contact BizNet Real Estate?",
            answer: `You can reach us by clicking the "Contact Us" button on our website or by calling us at [phone number]. 
          Our team is ready to assist you with any inquiries and provide support as needed.`,
        },
        {
            question: "Is my personal information secure with BizNet Real Estate?",
            answer: `Yes, we prioritize your privacy and security. BizNet Real Estate employs industry-standard security measures 
          to protect your personal information and ensure it remains confidential.`,
        },
    ];

    return (
        <>
            <Header />

            {/* hero container */}
            <div className="contact-parent">
                <div className="container-fluid hero-container-contact">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex align-items-center justify-content-center">
                                <div
                                    className="bg-white p-4 rounded-3 shadow-lg text-center"
                                    style={{ maxWidth: 500 }}
                                >
                                    <h2 className="h4 fw-bold text-dark">Contact Us</h2>
                                    <p className="mt-3 text-muted">
                                        We’re here to help! Our National Customer Service Team is
                                        available 8am - 5pm PST, seven days a week.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* contact way container */}
                <div className="container contact-way-container mt-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="d-flex flex-column align-items-center">
                                <i className="fa-solid fa-phone  contact-icon" />
                                <span className="fs-5 fw-semibold span-heading-contact">
                                    (844) 759-7732
                                </span>
                                <p className="text-muted text-center mt-2">
                                    Talk to a Customer Service Representative for help with our site,
                                    app, or finding a Biznet Agent.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex flex-column align-items-center">
                                <i className=" fa-solid fa-envelope contact-icon" />
                                <span className="fs-5 fw-semibold span-heading-contact">
                                    Message us
                                </span>
                                <p className="text-muted text-center mt-2">
                                    Send our Customer Service Team questions about our site, app, or
                                    finding a Biznet Agent.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="d-flex flex-column align-items-center">
                                <i className="fa-solid fa-share-nodes contact-icon" />
                                <span className="fs-5 fw-semibold span-heading-contact">
                                    Connect with us
                                </span>
                                {/* <p class="text-muted text-center mt-2">Follow us on social media.</p> */}
                                <div className="d-flex gap-2 mt-2">
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-facebook" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-x-twitter" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-x-twitter" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact "
                                    >
                                        <i className="fa-brands fa-x-twitter" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-instagram" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-pinterest" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-google" />
                                    </a>
                                    <a
                                        href="#"
                                        className="text-muted text-decoration-none hover:text-dark icon-share-contact"
                                    >
                                        <i className="fa-brands fa-linkedin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* biznet office */}
            </div>
            {/* faq section */}
            <FAQs faqs={faqData} />
            {/* footer */}

            <Footer />
        </>
    );
};

export default Contact;