import React from 'react';

const ImageWithText = ({
  content,
  imgSrc,
  altText,
  title,
  imagePosition = 'left', // Default position for the image
}) => {
  return (
    <div className="row align-items-center my-5">
      {imagePosition === 'left' ? (
        <>
          <div className="col-md-6 text-dark">
            <h2>{title}</h2>
            <p>{content}</p>
          </div>
          <div className="col-md-6">
            <img src={imgSrc} alt={altText} className="about-img rounded-3 img-fluid" />
          </div>
        </>
      ) : (
        <>
          <div className="col-md-6">
            <img src={imgSrc} alt={altText} className="about-img rounded-3 img-fluid" />
          </div>
          <div className="col-md-6 text-dark">
            <h2>{title}</h2>
            <p>{content}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ImageWithText;
