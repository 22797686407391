import { useEffect } from 'react';

const GetLocation = () => {
  // Function to automatically get the user's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const longitude = position.coords.longitude;
          const latitude = position.coords.latitude;
          console.log('Longitude:', longitude);
          console.log('Latitude:', latitude);
          // You can add further processing here if needed
        },
        (error) => {
          console.error('Error retrieving location:', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
  }, []);

  return null; // No UI is rendered
};

export default GetLocation;
