import React from "react";
import FeaturedSection from "../../../components/FeaturedSection";
import WhatsNewSection from "../../../components/WhatsNewSection";
import ServicesCardImage from "../../../components/ServicesCardImage";
import blog06 from "../../../assets/images/blog 06.jpg";
import blog07 from "../../../assets/images/blog 07.jpg";
import blog08 from "../../../assets/images/blog 08.jpg";
import blog09 from "../../../assets/images/blog 09.jpg";
import blog10 from "../../../assets/images/blog 10.jpg";
import blog11 from "../../../assets/images/blog 11.jpg";
import blog12 from "../../../assets/images/blog 12.jpg";
import blog13 from "../../../assets/images/blog 13.jpg";
import blog14 from "../../../assets/images/blog 14.jpg";
import blog15 from "../../../assets/images/blog 15.jpg";
import blog16 from "../../../assets/images/blog 16.jpg";
import blog17 from "../../../assets/images/blog 17.jpg";
import blog18 from "../../../assets/images/blog 18.jpg";
import blog19 from "../../../assets/images/blog 19.jpg";
import blog20 from "../../../assets/images/blog 20.jpg";
import blog21 from "../../../assets/images/blog 21.jpg";
import blog22 from "../../../assets/images/blog 22.jpg";
import blog23 from "../../../assets/images/blog 23.jpg";
import blog24 from "../../../assets/images/blog 24.jpg";
import blog25 from "../../../assets/images/blog 25.jpg";
import HousingSubscriptionForm from "../../../components/HousingSubscriptionForm";
import "../../../assets/css/Blog.css";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";

const Blog = () => {
  const cardsData = [
    {
      id: 1,
      image: blog06,
      text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
    },

    {
      id: 1,
      image: blog07,
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },

    {
      id: 1,
      image: blog08,
      text: "Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?",
    },

    {
      id: 1,
      image: blog09,
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },
  ];
  const cardsData01 = [
    {
      id: 1,
      image: blog10, // Updated image path
      alt: "House Image 1",
      text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
    },
    {
      id: 2,
      image: blog11, // Updated image path
      alt: "House Image 2",
      text: "What is a Buyer Agency Agreement, and Is It Required?",
    },
    {
      id: 3,
      image: blog12, // Updated image path
      alt: "House Image 3",
      text: "Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?",
    },
    {
      id: 4,
      image: blog13, // Updated image path
      alt: "House Image 4",
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },
  ];
  const cardsData02 = [
    {
      id: 1,
      image: blog13, // Updated image path
      alt: "House Image 1",
      text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
    },
    {
      id: 2,
      image: blog14, // Updated image path
      alt: "House Image 2",
      text: "What is a Buyer Agency Agreement, and Is It Required?",
    },
    {
      id: 3,
      image: blog15, // Updated image path
      alt: "House Image 3",
      text: "Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?",
    },
    {
      id: 4,
      image: blog16, // Updated image path
      alt: "House Image 4",
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },
  ];
  const cardsData03 = [
    {
      id: 1,
      image: blog18, // Updated image path
      alt: "House Image 1",
      text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
    },
    {
      id: 2,
      image: blog19, // Updated image path
      alt: "House Image 2",
      text: "What is a Buyer Agency Agreement, and Is It Required?",
    },
    {
      id: 3,
      image: blog20, // Updated image path
      alt: "House Image 3",
      text: "Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?",
    },
    {
      id: 4,
      image: blog21, // Updated image path
      alt: "House Image 4",
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },
  ];
  const cardsData04 = [
    {
      id: 1,
      image: blog22, // Updated image path
      alt: "House Image 1",
      text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
    },
    {
      id: 2,
      image: blog23, // Updated image path
      alt: "House Image 2",
      text: "What is a Buyer Agency Agreement, and Is It Required?",
    },
    {
      id: 3,
      image: blog24, // Updated image path
      alt: "House Image 3",
      text: "Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?",
    },
    {
      id: 4,
      image: blog25, // Updated image path
      alt: "House Image 4",
      text: "Should I Buy a House Now or Wait? Key Questions to Consider",
    },
  ];
  return (
    <>
      <Header />
      <main>
        <div className="blog">
          <div className="container my-5">
            <div className="row">
              {/* Featured Section */}
              <FeaturedSection />
              {/* What's New Section */}
              <WhatsNewSection />
            </div>
          </div>

          {/* home Sections */}
          <section id="featured" className="py-5 px-md-5 px-3 blog">
            {/* home Sections */}
            <div class="section-header d-flex align-items-center">
              <span className="fw-bold fs-5">Painting and Drywall</span>
              <div className="divider" />
              <a href="#">
                <button className="btn">see more</button>
              </a>
            </div>
            <ServicesCardImage cards={cardsData} />

            <div className="section-header">
              <span className="fw-bold fs-5"> Electrical Work </span>
              <div className="divider" />

              <a href="#">
                <button className="btn">see more</button>
              </a>
            </div>
            <ServicesCardImage cards={cardsData01} />

            <div className="section-header">
              <span className="fw-bold fs-5"> Remodeling and Renovation</span>
              <div className="divider" />

              <a href="#">
                <button className="btn">see more</button>
              </a>
            </div>
            <ServicesCardImage cards={cardsData02} />

            <div className="section-header">
              <span className="fw-bold fs-5"> Home​ Improvement​</span>
              <div className="divider" />

              <a href="#">
                <button className="btn">see more</button>
              </a>
            </div>
            <ServicesCardImage cards={cardsData03} />
            <div className="btn  d-flex justify-content-center my-4">
              <button className="btn btn-blog">Load More</button>
            </div>
          </section>

          {/* Subscription Section */}
          <section className="subscription-section">
            <div className="container">
              <HousingSubscriptionForm />
            </div>
          </section>

          <div className="container text-center  my-5">
            <h4>Follow Biznet</h4>
            <div className="social-icons">
              <a href="#" className="twitter" aria-label="Twitter">
                <i className="fab fa-twitter" />
              </a>
              <a href="#" className="facebook" aria-label="Facebook">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="#" className="pinterest" aria-label="Pinterest">
                <i className="fab fa-pinterest-p" />
              </a>
              <a href="#" className="linkedin" aria-label="LinkedIn">
                <i className="fab fa-linkedin-in" />
              </a>
              <a href="#" className="youtube" aria-label="YouTube">
                <i className="fab fa-youtube" />
              </a>
              <a href="#" className="instagram" aria-label="Instagram">
                <i className="fab fa-instagram" />
              </a>
            </div>
          </div>
          {/* Connect With A Biznet Agent */}
          <div className="end-section">
            <div className="container ">
              <h2 className="text-light">Connect With A Biznet Agent</h2>
              <div className="btn btn-blog my-4">
                <button className="btn btn-blog"> Get Started</button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default Blog;
