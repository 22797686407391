import './HousingSubscriptionForm.css';

const HousingSubscriptionForm = () => {
  return (
    <div className="container-fluid">
      <div className="subscription-section ">
        <div className="row p-4 form-container">
          {/* Text Column */}
          <div className="col-md-6 text-center text-md-end mb-3 mb-md-0">
            <p className="lead">Be the first to see the latest housing news and advice:</p>
          </div>

          {/* Form Column */}
          <div className="col-md-6">
            <form action="#" className="d-flex">
              <input type="email" className="form-control" placeholder="Email Address" required />
              <button className="btn btn-subscribe" type="submit">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 12H3m0 0l7 7m-7-7l7-7m7 7h7" />
                </svg>
              </button>
            </form>
            <small className="text-muted">
              By submitting your email you agree to Biznet’s <a href="#" className="text-decoration-none">Terms of Use</a> and <a href="#" className="text-decoration-none">Privacy Policy</a>.
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HousingSubscriptionForm;
