import React, { useEffect, useRef } from 'react';
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip } from 'chart.js';

const MonthlyPerformanceChart = () => {
    const canvasRef = useRef(null);
    let chartInstance = useRef(null);

    useEffect(() => {
        // Register components required for the bar chart
        Chart.register(BarController, BarElement, CategoryScale, LinearScale, Title, Tooltip);

        const ctx = canvasRef.current.getContext('2d');

        // Create a new chart instance
        chartInstance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['January', 'February', 'March', 'April', 'May'],
                datasets: [{
                    label: 'Performance',
                    data: [65, 59, 80, 81, 56],
                    backgroundColor: 'rgba(54, 162, 235, 0.6)',
                }]
            }
        });

        // Cleanup function to destroy chart instance when the component is unmounted or re-rendered
        return () => {
            if (chartInstance.current) {
                chartInstance.current.destroy();
            }
        };
    }, []);

    return <canvas ref={canvasRef} width="400" height="200"></canvas>;
}

export default MonthlyPerformanceChart;
