import React from 'react';

const ServicesCardImage = ({ cards }) => {
    return (
        <div className="container mt-5">
            {/* Card wrapper that keeps all cards in a single row */}
            <div className="row d-flex flex-row">
                {cards.map((card) => (
                    <div className="col-md-3 col-sm-6 mb-4" key={card.id}>
                        <div className="card h-100">
                            <img src={card.image} className="card-img-top" alt={`Card Image ${card.id}`} />
                            <div className="card-body">
                                <p className="card-text">{card.text}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ServicesCardImage;
