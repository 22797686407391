import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import "../../assets/css/Auth.css";

const ForgotPassword = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        setLoading(true);

        const formData = new URLSearchParams();
        formData.append("email", email);

        try {
            const response = await fetch("https://api.biznetusa.com/api/forgot-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData.toString(),
            });

            const data = await response.json();

            if (response.ok) {
                toast.success("Password reset link sent to your email!");
                setTimeout(() => {
                    navigate("/ResetPassword"); 
                }, 2000);
            } else {
                throw new Error(data.message || "Failed to send reset link");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <main className="background_color_fixed">
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <div
                        className="card body_color shadow-lg p-4"
                        style={{ maxWidth: "26rem", width: "100%" }}
                    >
                        <a className="text-decoration-none" href="/">
                            <h2 className="h4 fw-bold py-3 mb-3">Forgot Password</h2>
                        </a>
                        <div className="mb-3 d-flex flex-row gap-5">
                            <a className="text-decoration-none" href="/login">
                                <h3 className="h6 fw-semibold border-bottom border-2">SignIn</h3>
                            </a>
                            <a className="text-decoration-none" href="/signup">
                                <h3 className="h6 fw-semibold border-bottom border-2">
                                    New account
                                </h3>
                            </a>
                        </div>
                        <form onSubmit={handleForgotPassword}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    placeholder="Enter email"
                                    className="form-control"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn w-100" disabled={loading}>
                                {loading ? "Sending..." : "Send Reset Link"}
                            </button>
                        </form>
                        <div className="my-4 border-top text-center pt-3">
                            <p className="text-muted">Or connect with:</p>
                            <div className="d-flex justify-content-center flex-column gap-3 mt-2">
                                <button className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-apple me-2" />
                                    Continue with Apple
                                </button>
                                <button className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-facebook-f me-2" />
                                    Continue with Facebook
                                </button>
                                <button className="btn btn-outline-secondary d-flex align-items-center">
                                    <i className="fab fa-google me-2" />
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </>
    );
};

export default ForgotPassword;
