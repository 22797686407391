import React, { useEffect, useState } from 'react';
import axios from 'axios';

const HomeBannerCard = () => {
  const [banners, setBanners] = useState([]);
  const imagePath = 'https://api.biznetusa.com/uploads/banners/';

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get('https://api.biznetusa.com/api/get-banners');
        setBanners(response.data.banners);
      } catch (error) {
        console.error('Error fetching banners:', error);
      }
    };
    fetchBanners();
  }, []);

  return (

    <div className="col-lg-7" style={{ height: 300 }}>
      <div id="propertyCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          {banners.length > 0 ? (
            banners.map((banner, index) => (
              <div key={banner.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img
                  src={banner.image ? `${imagePath}${banner.image}` : 'default-image.jpg'}
                  alt={banner.title}
                  className="img-fluid rounded-0 mb-3"
                  style={{
                    width: '100%',
                    height: '380px',
                    objectFit: 'cover'
                  }}
                />
              </div>
            ))
          ) : (
            <p>No banners available</p>
          )}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#propertyCarousel" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#propertyCarousel" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
        <div className="p-3 rounded bg-white w-50 float-end take_a_position_bottom">
          {banners.length > 0 && (
            <>
              <h5 className="fw-semibold text-dark">{banners[0].title}</h5>
              <div className="d-flex justify-content-between">
                <p className="text-muted">{banners[0].desc}</p>
                <p className="h5 fw-bold text-dark">{banners[0].price}</p>
              </div>
              <button className="btn w-100">View home</button>
            </>
          )}
        </div>
      </div>
    </div >
  );
};

export default HomeBannerCard;
