import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/css/Auth.css"; // Ensure this CSS file has your custom styling

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    // Extract token and email from URL query parameters
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const email = queryParams.get("email");

    // Handle form submission
    const handleResetPassword = async (e) => {
        e.preventDefault();

        // Passwords match check
        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match!");
            return;
        }

        setLoading(true);

        // Prepare form data
        const formData = new URLSearchParams();
        formData.append("email", email);
        formData.append("token", token);
        formData.append("new_password", newPassword);

        try {
            const response = await fetch("https://api.biznetusa.com/api/reset-password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: formData.toString(),
            });

            const data = await response.json();

            if (response.ok) {
                toast.success("Password has been reset successfully!");
                setTimeout(() => {
                    navigate("/"); // Redirect to login page after success
                }, 2000);
            } else {
                throw new Error(data.message || "Failed to reset password");
            }
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <main className="background_color_fixed">
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <div
                        className="reset-password-card body_color shadow-lg p-4"
                        style={{ maxWidth: "26rem", width: "100%" }}
                    >
                        <h2 className="h4 fw-bold py-3 mb-3 text-center">Reset Password</h2>
                        <form onSubmit={handleResetPassword}>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="newPassword">
                                    New Password
                                </label>
                                <input
                                    type="password"
                                    id="newPassword"
                                    placeholder="Enter new password"
                                    className="form-control"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mb-3">
                                <label className="form-label" htmlFor="confirmPassword">
                                    Confirm New Password
                                </label>
                                <input
                                    type="password"
                                    id="confirmPassword"
                                    placeholder="Confirm new password"
                                    className="form-control"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                                {loading ? "Resetting..." : "Reset Password"}
                            </button>
                        </form>
                    </div>
                </div>
            </main>
            <ToastContainer />
        </>
    );
};

export default ResetPassword;
