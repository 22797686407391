import React from 'react';

const Sub2DealCard = ({ imgSrc, altText, title, description }) => {
    return (
        <div className="col-md-4 mt-3">
            <div className="card h-100">
                <img src={imgSrc} alt={altText} className="card-img-top" />
                <div className="card-body">
                    <h3 className="card-title">{title}</h3>
                    <p className="card-text">{description}</p>
                </div>
            </div>
        </div>
    );
};

export default Sub2DealCard;
