import React from "react";
import InvestorHeader from "../../components/InvestorHeader";
import Header from "../../components/header";
import Footer from "../../components/Footer";

const TrackRIO = () => {
  return (
    <>
      <Header />
      <InvestorHeader />
      {/* Track ROI Section */}
      <div className="container mt-5">
        <h2 className="text-center mb-4">Track Return on Investment (ROI)</h2>
        <div className="row">
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="investmentSelect" className="form-label">
                Select Investment
              </label>
              <select className="form-select" id="investmentSelect">
                <option>Investment 1</option>
                <option>Investment 2</option>
                <option>Investment 3</option>
                <option>Investment 4</option>
                <option>Investment 5</option>
                <option>Investment 6</option>
              </select>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mb-3">
              <label htmlFor="roiValue" className="form-label">
                ROI (%)
              </label>
              <input
                type="text"
                className="form-control"
                id="roiValue"
                placeholder="20%"
              />
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 text-center">
            <button className="btn btn-success">Calculate ROI</button>
          </div>
        </div>
        <div className="">
          <h3>Track ROI</h3>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Investment</th>
                <th>Amount</th>
                <th>ROI (%)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tech Startup</td>
                <td>$10,000</td>
                <td>
                  <span className="roi-positive">+12%</span>
                </td>
              </tr>
              <tr>
                <td>Real Estate</td>
                <td>$25,000</td>
                <td>
                  <span className="roi-negative">-4%</span>
                </td>
              </tr>
              <tr>
                <td>Energy Fund</td>
                <td>$15,000</td>
                <td>
                  <span className="roi-positive">+8%</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default TrackRIO;
