import React from "react";
import { Link } from "react-router-dom";

const ContractorHeader = () => {
    return (
        <>
            <>
                "
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/ManageServiceContractor">
                            Contractor Panel
                        </Link>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#navbarNav"
                            aria-controls="navbarNav"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link
                                        className="nav-link active"
                                        aria-current="page"
                                        to="/ManageServiceContractor"
                                    >
                                        Manage Services
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ProjectInquiries">
                                        Project Inquiries
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ScheduleProject">
                                        Schedule Projects
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/TaskProgress">
                                        Track Progress
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ClientCommunication">
                                        Client Communication
                                    </Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/ReviewManagement">
                                        Review Management
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            
            </>


        </>

    )
}

export default ContractorHeader;