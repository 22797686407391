import React from "react";
import ProductAllCards from "../ProductAllCards";

const List = () => {
    return (
        <>
            <div id="List" className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-sm-6 mt-4">
                        <ProductAllCards />
                    </div>
                </div>
            </div>

            {/* Modal  */}

        </>
    );
};
export default List;
