import React from "react";


const MissionVision = () => {
    return (
        <>
            <section className="py-5 bg-light">
                <div className="container">
                    <h2 className="section-title ">Our Mission &amp; Vision</h2>
                    <div className="row">
                        {/* Mission Card */}
                        <div className="col-md-6 ">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h3 className="card-title">Our Mission</h3>
                                    <p className="card-text">
                                        Our mission is to provide our clients with exceptional real
                                        estate services, ensuring seamless transactions, valuable
                                        investments, and unmatched client satisfaction. We aim to build
                                        lasting relationships by delivering personalized solutions that
                                        meet the unique needs of each client, whether residential,
                                        commercial, or industrial. Our team is committed to operating
                                        with integrity, transparency, and innovation, making the buying,
                                        selling, and investing process as smooth .
                                    </p>
                                </div>
                            </div>
                        </div>
                        {/* Vision Card */}
                        <div className="col-md-6 ">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <h3 className="card-title">Our Vision</h3>
                                    <p className="card-text">
                                        Our vision is to be a global leader in the real estate industry
                                        by consistently driving innovation, embracing emerging
                                        technologies, and adapting to the evolving needs of the market.
                                        We aspire to shape the future of real estate by fostering a
                                        culture of excellence, sustainability, and social
                                        responsibility. Our goal is to create a positive impact on the
                                        communities we serve, while maintaining a commitment to ethical
                                        practices and delivering unparalleled value to our clients
                                        worldwide.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MissionVision;