import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/Footer";
import "./About.css";
import aboutImage1 from "../../assets/images/real estate _20.jpg";
import ImagewithText from "../../components/ImagewithText";
import MissionVision from "../../components/MissionVision";
import Testimonial from "../../components/Testimonial";
import CoreValueCard from "../../components/CoreValueCard";
import { ToastContainer, toast } from "react-toastify";

const About = () => {
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch("https://api.biznetusa.com/api/store-getintouch", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Form submitted successfully:", result);
                toast.success("Your message has been sent successfully!");
                setFormData({ name: "", email: "", message: "" });
            } else {
                console.error("Error submitting form:", response.statusText);
                toast.error("Failed to send your message. Please try again.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again later.");
        }
    };

    return (
        <>
            <ToastContainer />
            <Header />
            <main className="about">
                {/* Hero Section */}
                <section className="">
                    <div className="container-fluid hero d-flex justify-content-center align-items-center flex-column">
                        <h1 className="">Welcome to Biznet</h1>
                        <p className="">
                            Leading the Real Estate Market with Integrity and Innovation.
                        </p>
                        <a href="#about" className="btn btn-primary ">
                            Learn More About Us
                        </a>
                    </div>
                </section>
                {/* About Section */}
                <section id="about" className="py-5">
                    <div className="container">
                        <h2 className="section-title ">About Biznet</h2>
                        <div className="row align-items-center">
                            <ImagewithText
                                content="Biznet has been at the forefront of real estate, offering unparalleled services to clients. From residential to commercial, we have the expertise and commitment to help you find your perfect space."
                                imgSrc={aboutImage1}
                                altText="About Biznet"
                            />
                        </div>
                    </div>
                </section>
                <MissionVision />
                {/* Core Values Section */}
                <section className="py-5 bg-light">
                    <div className="container">
                        <h2 className="section-title ">Our Core Values</h2>
                        <div className="row">
                            <CoreValueCard
                                title="Integrity"
                                text="We uphold transparency, honesty, and ethical practices in all our transactions, ensuring that our clients' interests are always our top priority."
                            />
                            <CoreValueCard
                                title="Innovation"
                                text="We continually embrace new trends and technologies to offer creative, cutting-edge solutions that meet the evolving demands of the real estate industry."
                            />
                            <CoreValueCard
                                title="Excellence"
                                text="We are committed to delivering outstanding results in every project, consistently striving for the highest standards of quality and client satisfaction."
                            />
                        </div>
                    </div>
                </section>
                <Testimonial />
                {/* Contact Section */}
                <section id="contact" className="py-2 bg-light">
                    <div className="container">
                        <h2 className="text-center mb-4">Get in Touch</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-3">
                                <div className="col-md-6">
                                    <label htmlFor="name" className="form-label">
                                        Your Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        id="name"
                                        placeholder="Your Name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label htmlFor="email" className="form-label">
                                        Your Email
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        name="email"
                                        id="email"
                                        placeholder="Your Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-md-12">
                                    <label htmlFor="message" className="form-label">
                                        Your Message
                                    </label>
                                    <textarea
                                        className="form-control"
                                        name="message"
                                        id="message"
                                        placeholder="Your Message"
                                        rows={5}
                                        value={formData.message}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="text-center">
                                <button type="submit" className="btn btn-outline-info">
                                    Send Message
                                </button>
                            </div>
                        </form>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
};

export default About;

