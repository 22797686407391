import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <>

            <footer className="bg-light text-dark py-4">
                <div className="container">
                    <div className="row g-4">
                        <div className="col-6 col-md-2">
                            <h6 className="fw-semibold">Join us</h6>
                            <ul className="list-unstyled mt-2">
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Become an Agent
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Get referrals
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Careers
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-2">
                            <h6 className="fw-semibold">About us</h6>
                            <ul className="list-unstyled mt-2">
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Why BIZNET?
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Community Impact
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Diversity &amp; Inclusion
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Life at BIZNET
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Press
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Investors
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="Blog.html"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Blog
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Real Estate News
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-2">
                            <h6 className="fw-semibold">Find us</h6>
                            <ul className="list-unstyled mt-2">
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Help Center
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Advertise
                                    </a>
                                </li>
                            </ul>
                            <div className="d-flex my-3">
                                <a href="javascript:void(0)" className="text-muted me-2">
                                    <i className="fa-brands fa-facebook" />
                                </a>
                                <a href="javascript:void(0)" className="text-muted me-2">
                                    <i className="fa-brands fa-twitter" />
                                </a>
                                <a href="javascript:void(0)" className="text-muted">
                                    <i className="fa-brands fa-instagram" />
                                </a>
                            </div>
                            <h6 className="fw-semibold">Subsidiaries</h6>
                            <ul className="list-unstyled mt-2">
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Rent.
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        ApartmentGuide
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Bay Equity Home Loans
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="javascript:void(0)"
                                        className="text-muted text-decoration-none hover-link"
                                    >
                                        Title Forward
                                    </a>
                                </li>
                            </ul>
                            <h6 className="fw-semibold">Countries</h6>
                            <ul className="list-unstyled mt-2">
                                <li className="d-flex align-items-center">
                                    <span>🇺🇸 United States</span>
                                </li>
                                <li className="d-flex align-items-center">
                                    <span>🇨🇦 Canada</span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-6 col-md-6">
                            <div className="bg-light p-4 text-muted">
                                <p className="mb-2 ">
                                    Copyright: © 2024 BIZNET. All rights reserved.
                                </p>
                                <p className="mb-2 ">
                                    Updated January 2023: By searching, you agree to the{" "}
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        Terms of Use
                                    </a>
                                    , and{" "}
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        Privacy Policy
                                    </a>
                                    .
                                </p>
                                <p className="mb-2 ">
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        Do not sell or share my personal information.
                                    </a>
                                </p>
                                <p className="mb-2 ">
                                    Biznet and all BIZNET variants, TITLE FORWARD, WALK SCORE, and the
                                    R logos, are trademarks of BIZNET Corporation, registered or
                                    pending in the USPTO.
                                </p>
                                <p className="mb-2 ">California DRE javascript:void(0)01521930</p>
                                <p className="mb-2 ">
                                    Biznet is licensed to do business in New York as BIZNET Real
                                    Estate.
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        NY Standard Operating Procedures
                                    </a>
                                </p>
                                <p className="mb-2 ">New Mexico Real Estate Licenses</p>
                                <p className="mb-2 ">
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        TREC: Info About Brokerage Services, Consumer Protection Notice
                                    </a>
                                </p>
                                <p className="mt-4 ">
                                    If you are using a screen reader, or having trouble reading this
                                    website, please call BIZNET Customer Support for help at{" "}
                                    <a href="tel:1-844-759-7732" className="text-decoration-none ">
                                        1-844-759-7732
                                    </a>
                                    .
                                </p>
                                <p className="mt-4  font-weight-bold">
                                    🏠 BIZNET IS COMMITTED TO AND ABIDES BY THE FAIR HOUSING ACT AND
                                    EQUAL OPPORTUNITY ACT.
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        READ BIZNET'S FAIR HOUSING POLICY
                                    </a>{" "}
                                    AND THE
                                    <a href="javascript:void(0)" className="text-decoration-none ">
                                        NEW YORK STATE FAIR HOUSING NOTICE
                                    </a>
                                    .
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>


        </>
    )
}

export default Footer;
