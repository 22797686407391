import React from "react";
import img1 from "../../assets/images/blog 02.jpg";
import img2 from "../../assets/images/blog 03.jpg";
import img3 from "../../assets/images/blog 03.jpg";
import img4 from "../../assets/images/blog 04.jpg";

const WhatsNewSection = () => {
    return (
        <div className="col-lg-4 col-md-12">
            <h4>What's New</h4>
            <hr />
            <div className="news-list">
                {[
                    {
                        imgSrc: img1,
                        title: 'What is a Buyer Agency Agreement, and Is It Required?',
                    },
                    {
                        imgSrc: img2,
                        title: 'Are Mortgage Rates Dropping? Explaining What’s Happening to Interest Rates in 2024',
                    },
                    {
                        imgSrc: img3,
                        title: 'Who Pays the Real Estate Agent Commission?',
                    },
                    {
                        imgSrc: img4,
                        title: 'Should I Lock in My Mortgage Rate Now That Rates Are Dropping?',
                    },
                ].map((newsItem, index) => (
                    <div key={index} className="news-card mb-4">
                        <div className="card border-0">
                            <img src={newsItem.imgSrc} alt="What's New" />
                            <div className="card-body">
                                <h6 className="card-title">{newsItem.title}</h6>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
export default WhatsNewSection;