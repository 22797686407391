import React from "react";

const ProductMap = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <iframe
              className="map_imtegrate"
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14042.045651016564!2d70.41938789999999!3d28.3736176!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1727085737551!5m2!1sen!2s"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductMap;
