import React from 'react';


const PropertyCard = ({ id, price, images, beds, baths, area, address }) => {
    return (
        <div className="col-sm-6 col-lg-4 mb-4 Main_portion">
            <a className='text-decoration-none text-dark' href="javascript:void(0)">
                <div className="card">
                    {/* Start of Carousel */}
                    <div id={`carouselProperty${id}`} className="carousel slide" data-bs-ride="carousel">
                        <div className="carousel-inner">
                            {images.map((img, index) => (
                                <div
                                    key={index}
                                    className={`carousel-item ${index === 0 ? 'active' : ''}`}
                                >
                                    <img src={img} alt={`Property ${id}`} className="d-block w-100" />
                                </div>
                            ))}
                        </div>
                        {/* Carousel controls */}
                        <button
                            className="carousel-control-prev"
                            type="button"
                            data-bs-target={`#carouselProperty${id}`}
                            data-bs-slide="prev"
                        >
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                            className="carousel-control-next"
                            type="button"
                            data-bs-target={`#carouselProperty${id}`}
                            data-bs-slide="next"
                        >
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                    {/* End of Carousel */}

                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <h3 className="h5 fw-bold">${price}</h3>
                            <div>
                                <i className="fa-solid fa-share"></i>
                                <i className="fa-regular fa-heart ms-2"></i>
                            </div>
                        </div>
                        <p className="mb-1 small">{beds} beds | {baths} baths | {area} sq ft</p>
                        <p className="small">{address}</p>
                    </div>
                  
                </div>
            </a>
        </div>
    );
};



export default PropertyCard;
