import React, { useEffect, useState } from "react";
import MainCards from "../../components/MainCards";
import HomeBannerCard from "../../components/HomeBannerCard";
import img1 from '../../assets/images/real estate _1.jpg';
import img2 from '../../assets/images/real estate _10.jpg';
import img3 from '../../assets/images/real estate _11.jpg';
import CityRealEstateList from "../../components/CityRealEstateList";
import RealEstateTabs from "../../components/RealEstateTabs";
import Footer from "../../components/Footer";
import Header from "../../components/header";
import AppartementsRealEstate from "../../components/AppartementsRealEstate";
import LicenseVerification from "../../components/LicenseVerification";
import GetLocation from '../../components/ProductByLocation'
import ChatComponent from "../../components/ChatComponent";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";


const Home = () => {
    const propertyImages = [img1, img2, img3];
    const [showModal, setShowModal] = useState(false); // Show the modal initially
    const [userRole, setUserRole] = useState("Guest"); // Default to 'Guest'
    const [userId, setUserId] = useState(null)

    // Fetch user role from localStorage
    const fetchUserRole = () => {
        const storedUserRole = localStorage.getItem("roles");
        setUserRole(storedUserRole || "Guest"); // Set 'Guest' if no role is found
    };

    const fetchUserId = () => {
        const storedUserId = localStorage.getItem("user_id");
        setUserId(storedUserId || null);
    }

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`https://api.biznetusa.com/api/get-userprofiling/${userId}`);
                setShowModal(response.data.user_profiling.length === 0)
                console.log(response.data);
            } catch (err) {
                console.error(err)
            }
        }
        if (userId) {
            fetchUserProfile();
        }
    }, [userId]);

    useEffect(() => {
        fetchUserRole();
        fetchUserId();

        const handleStorageChange = (event) => {
            if (event.key === "roles") {
                fetchUserRole();
            }
        };

        window.addEventListener("storage", handleStorageChange);
        return () => {
            window.removeEventListener("storage", handleStorageChange);
        };
    }, []);

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData(e.target); // Collect form data

        // Append additional fields if needed
        formData.append("user_id", localStorage.getItem("user_id") || "guest");

        const data = Object.fromEntries(formData.entries()); // Convert FormData to an object

        try {
            const response = await fetch("https://api.biznetusa.com/api/store-userprofiling", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (response.ok) {
                const result = await response.json();
                console.log("Data submitted successfully:", result);
                toast.success("Form submitted successfully!");
                setShowModal(false); // Close the modal
            } else {
                console.error("Error submitting form:", response.statusText);
                toast.error("Failed to submit the form.");
            }
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("An error occurred. Please try again later.");
        }
    };

    return (
        <>
            {(showModal && userRole !== "2") && (
                <div>
                    <div className="modal-backdrop fade show backdrop-blur"></div>
                    <div className="modal fade show" tabIndex="-1" style={{ display: "block" }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">User Profiling</h5>
                                </div>
                                <div className="modal-body text-white">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mb-3">
                                            <label htmlFor="current_credit_score" className="form-label mt-3 text-white">
                                                What is your current credit score?
                                            </label>
                                            <select name="current_credit_score" className="form-select" id="current-credit-score">
                                                <option value="under-600">Under 600</option>
                                                <option value="601-649">601-649</option>
                                                <option value="650-699">650-699</option>
                                                <option value="700-749">700-749</option>
                                                <option value="above-750">Above 750</option>
                                            </select>

                                            <label htmlFor="employment_status" className="form-label mt-3 text-white">
                                                What is your employment status?
                                            </label>
                                            <select name="empoyment_status" className="form-select" id="employment-status">
                                                <option value="full-time">Full-Time</option>
                                                <option value="part-time">Part-Time</option>
                                                <option value="self-employed">Self-Employed</option>
                                                <option value="unemployed">Unemployed</option>
                                                <option value="retired">Retired</option>
                                            </select>

                                            <label htmlFor="purchase_home_or_investing_property" className="form-label mt-3 text-white">
                                                How soon are you looking to buy a home or investment property?
                                            </label>
                                            <select name="purchase_home_or_investing_property" className="form-select" id="buying-timeline">
                                                <option value="within-3-months">Within 3 Months</option>
                                                <option value="within-3-6-months">Within 3-6 Months</option>
                                                <option value="6-months-plus">6+ Months</option>
                                                <option value="no-specified-timeline">No Specified Timeline</option>
                                            </select>

                                            <label htmlFor="buy_home_or_investing_property" className="form-label mt-3 text-white">
                                                What is your primary goal in purchasing a home or investment property?
                                            </label>
                                            <select name="buy_home_or_investing_property" className="form-select" id="goal">
                                                <option value="home-ownership">Home Ownership</option>
                                                <option value="investment">Investment</option>
                                                <option value="both">Both</option>
                                            </select>

                                            <label htmlFor="opening_working_with_private_lender" className="form-label mt-3 text-white">
                                                Are you open to working with private lenders?
                                            </label>
                                            <select name="opening_working_with_private_lender" className="form-select" id="private-lenders">
                                                <option value="yes">Yes</option>
                                                <option value="no">No</option>
                                                <option value="both">Both</option>
                                            </select>

                                            <label htmlFor="fimilar_with_buy_or_investing_property" className="form-label mt-3 text-white">
                                                How familiar are you with the process of buying a home or investment property?
                                            </label>
                                            <select name="fimilar_with_buy_or_investing_property" className="form-select" id="familiarity">
                                                <option value="very-familiar">Very Familiar</option>
                                                <option value="somewhat-familiar">Somewhat Familiar</option>
                                                <option value="not-familiar">Not Familiar at all</option>
                                            </select>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="working_as_realtor" id="working-realtor" />
                                                <label htmlFor="working_as_realtor" className="form-label mt-3 text-white">
                                                    Are you currently working with a realtor to help you find a property?
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="bought_house_before" id="bought_house_before" />
                                                <label htmlFor="bought_house_before" className="form-label mt-3 text-white">
                                                    Have you bought a house before?
                                                </label>
                                            </div>
                                        </div>

                                        <div className="text-end">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <ToastContainer />
            <Header />
            <GetLocation />
            <main className="Main_portion">
                <div className="container-fluid px-4 pb-4 g-0 rounded bg-light">
                    <div className="row">
                        <RealEstateTabs />
                        <HomeBannerCard
                            images={propertyImages}
                            location="Chevy Chase, MD"
                            price="$3,599,000"
                            title="Biznet PREMIER LISTING"
                        />
                    </div>
                </div>
                <MainCards />
                <div className="container py-4">
                    <h3 className="m-3">Search for homes by city</h3>
                    <CityRealEstateList />
                    <hr className="my-5" />
                    <h3 className="m-3">Search for apartments by city</h3>
                    <AppartementsRealEstate />
                    <hr className="my-5" />
                    <h3 className="m-3">Search for houses for rent by city</h3>
                    <CityRealEstateList />
                </div>
            </main>
            <Footer />
        </>
    );
};

export default Home;

