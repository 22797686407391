import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './LicenseVerification.css'; // Importing custom styles

function LicenseVerification() {
  return (
    <div className="license-verification-page">
      {/* Custom Header (Replace with your site's Header component if available) */}
      <header className="custom-header">
        <Container>
          <Row>
            <Col>
              <h1 className="header-title">Biznet License Verification</h1>
            </Col>
          </Row>
        </Container>
      </header>

      {/* Main Content Section */}
      <Container className="main-content">
        <Row className="text-center">
          <Col>
            <h2 className="content-title">Verify a Real Estate Agent's License</h2>
            <p className="content-description">
              Use the ARELLO verification tool below to check the status of a real estate agent's license.
            </p>
            {/* Iframe displaying the ARELLO license verification page */}
            <iframe 
              src="https://www.arello.com/search/" 
              title="License Verification"
              width="100%" 
              height="600" 
              style={{ border: '1px solid #ccc' }} 
              allowFullScreen
            ></iframe>
          </Col>
        </Row>
      </Container>

      {/* Custom Footer (Replace with your site's Footer component if available) */}
      <footer className="custom-footer">
        <Container>
          <Row>
            <Col>
              <p>© 2024 Biznet. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
}

export default LicenseVerification;
