import React from 'react';

const BiznetCorporation = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="p-4">
                    <h2 className="text-center mb-4 h3 fw-bold">Biznet Corporation at a Glance</h2>
                    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">559,000</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Customers bought or sold homes with us through 2023</p>
                            </div>
                            {/* Hello */}
                        </div>
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">$281B</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Worth of homes bought or sold by our customers through 2023</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">50 million</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Average monthly visitors to our website and mobile app in 2023</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">4,693</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Employees</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">1,776</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Average number of lead agents for 2023</p>
                            </div>
                        </div>
                        <div className="col">
                            <div className="bg-white p-4 rounded shadow-sm">
                                <h3 className="h2 fw-semibold">100 +</h3>
                                <hr className="border-2 border-success my-2" />
                                <p className="text-muted">Markets</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BiznetCorporation;
