import React from "react";
import './Developers.css'
import ImagewithText from "../../../components/ImagewithText";
import Developerimg from "../../../assets/images/Developer.jpg";
import img1 from "../../../assets/images/bolg cara 10.jpg";
import "../../../assets/css/Developers.css";
import ServiceCards from "../../../components/ServiceCards";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import ServicesCardImage from "../../../components/ServicesCardImage";
import img2 from "../../../assets/images/blog 06.jpg";
import img3 from "../../../assets/images/blog 07.jpg";
import img4 from "../../../assets/images/blog 08.jpg";
import img5 from "../../../assets/images/blog 09.jpg";
import ContactForm from "../../../components/ContactForm";


const Developers = () => {
    const services = [
        {
            icon: 'bi-house-fill',
            title: 'Custom Software Development',
            description: 'Our approach focuses on flexibility, scalability, and efficiency, enabling businesses to address specific challenges and gain a competitive edge.'
        },
        {
            icon: 'bi-currency-dollar',
            title: 'Web Application Development',
            description: 'We utilize the latest technologies and frameworks to develop robust web applications that cater to your specific needs.'
        },
        {
            icon: 'bi-bar-chart-line-fill',
            title: 'Mobile App Solutions',
            description: 'We understand the unique challenges and opportunities in mobile app development, from optimizing for various screen sizes and device types.'
        }
    ];
    const services1 = [
        {
            icon: 'bi bi-house-fill',
            title: 'Database Management',
            description: 'New home builds, renovations, and extensions anywhere.'
        },
        {
            icon: 'bi bi-currency-dollar',
            title: 'Software Maintenance',
            description: 'Office buildings, retail spaces, and industrial facilities.'
        },
        {
            icon: 'bi bi-bar-chart-line-fill',
            title: 'Backend Development',
            description: 'Overseeing all aspects of construction from planning to completion.'
        }
    ];

    const cardsData = [
        {
            id: 1,
            image: img2,
            text: "Can You Negotiate Real Estate Commissions? Yes, and Here's How",
        },
        {
            id: 2,
            image: img3,
            text: 'What is a Buyer Agency Agreement, and Is It Required?',
        },
        {
            id: 3,
            image: img4,
            text: 'Do You Have to Pay A Real Estate Agent if You Decide Not to Buy or Sell A House?',
        },
        {
            id: 4,
            image: img5,
            text: 'Should I Buy a House Now or Wait? Key Questions to Consider',
        },
    ];

    return (
        <>
            <Header />
            <main className="Realtor_main_section">
                {/* Hero Section */}
                <section id="Developer" className="bg-light text-center p-5">
                    <div className="container">
                        <h1 className="text-light ">Expert Developers In The World</h1>
                        <p className="lead">
                            Many of these expert developers have developed widely used tools like
                            Linux, Python, Java, and JavaScript, among others.
                        </p>
                        <a href="#featured" className="btn btn-primary">
                            More Developers
                        </a>
                    </div>
                </section>
                {/* ==================================== */}
                {/* About Section */}
                <section id="about" className="py-5">
                    <div className="container">
                        <div className="row">
                            <ImagewithText
                                title="About Us"
                                content=" We are a team of professional developers dedicated to helping
                                    clients bring their digital visions to life with precision and ease.
                                    Just like master architects of the digital world, we craft solutions
                                    that are not only functional but elegant, scalable, and intuitive.
                                    With years of hands-on experience, we have navigated the evolving
                                    landscape of software development, from coding intricate systems to
                                    troubleshooting the smallest of bugs."

                                imgSrc={Developerimg}
                            />

                        </div>
                    </div>
                </section>
                {/* ====================journy start  =================== */}
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <img
                                src={img1}
                                alt=""
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-lg-6">
                            <h1 className="">The Beginning of Innovation</h1>
                            <p>
                                Our Journy start form a small business in city and then our team our
                                worked hard togather and work lates nights.{" "}
                            </p>
                            <ul>
                                <li>Collaboration and Partnership</li>
                                <li>Commitment to Excellence</li>
                                <li>Clear Communication</li>
                                <li>Trust and Reliability</li>
                                <li>Satisfaction and Long-term Success</li>
                                <li>Continuous Improvement</li>
                                <li>Quality Assurance</li>
                                <li>On-time and On-budget Delivery</li>
                                <li>Attention to Safety</li>
                                <li>Accountability and Ownership</li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* Services Section */}
                <section id="services" className="bg-light py-5">
                    <div className="container">
                        <h2 className="text-center mb-5">Our Services</h2>
                        <ServiceCards services={services} />
                        {/* General Contractor Services Section */}
                        <h1 className="text-center mt-5">General Developers Services:</h1>
                        <ServiceCards services={services1} />

                    </div>
                </section>
                {/* Featured Listings Section */}
                <section id="featured" className="py-5 blog">
                    {/* home Sections */}
                    <div className="container mt-5">
                        {/* Card wrapper that keeps all cards in a single row */}
                        <div className="section-header">
                            <span> Cloud Platforms </span>
                            <div className="divider" />

                            <a href="#" className="">
                                See more
                            </a>
                        </div>
                        <div>
                            <ServicesCardImage cards={cardsData} />

                        </div>
                    </div>
                    <div className="container mt-5">
                        {/* Card wrapper that keeps all cards in a single row */}
                        <div className="section-header">
                            <span> Continuous Integration / Continuous Deployment (CI/CD) ​</span>
                            <div className="divider" />

                            <a href="#" className="see-more-link">
                                See more
                            </a>
                        </div>
                        <ServicesCardImage cards={cardsData} />

                    </div>
                    <div className="container mt-5">
                        {/* Card wrapper that keeps all cards in a single row */}
                        <div className="section-header d-flex justify-content-between align-items-center">
                            <span>API Management</span>
                            <div className="divider" />
                            <a href="#" className="see-more-link">
                                See more
                            </a>
                        </div>

                        <ServicesCardImage cards={cardsData} />

                    </div>
                    {/* ========================================== */}
                    <div className="container mt-5">
                        <div className="card p-4 shadow">
                            <h2 className="title">CEO</h2>
                            <h5 className="subtitle text-muted">Director</h5>
                            <p className="description">
                                Developers are the architects and builders of the digital world,
                                transforming ideas into functional software that powers everything
                                from websites to mobile apps, cloud systems, and artificial
                                intelligence. They are key drivers of innovation in today’s
                                technology- driven society, playing a critical role in industries as
                                diverse as healthcare, finance, entertainment, education, and beyond.
                                With the increasing reliance on digital tools, the demand for skilled
                                developers continues to grow globally. Types of Developers Developers
                                are categorized into various types based on their expertise and the
                                technologies they work with. Front-End Developers: These developers
                                focus on the client-side of web and mobile applications. They create
                                what users see and interact with. Front-end developers use languages
                                like HTML, CSS, and JavaScript to design responsive, visually
                                appealing, and user-friendly interfaces. Their work ensures a seamless
                                and intuitive user experience. Back-End Developers: Back-end
                                developers manage the server-side of applications, focusing on the
                                architecture and databases that store and process data. They handle
                                tasks such as server management, database creation, and ensuring that
                                data is securely transmitted between the server and the client. They
                                use programming languages like Python, Java, Ruby, and PHP, as well as
                                databases like SQL, MongoDB, and PostgreSQL.
                            </p>
                        </div>
                    </div>
                    {/* ============================================== */}
                    <div className="btn  d-flex justify-content-center my-4">
                        <button className="btn btn-blog">Load More</button>
                    </div>
                    {/* Contact Section */}
                    <section id="contact" className=" bg-light">
                       <ContactForm/>
                    </section>
                </section>
            </main>
            <Footer />


        </>
    );


};
export default Developers;