import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const AppartementsRealEstate = () => {
  // State to manage city data from the API
  const [Appartements, setAppartements] = useState([]);
  // State to manage visibility of the second half of the cities
  const [showMore, setShowMore] = useState(false);

  // Fetch cities from the API when the component mounts
  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get("https://api.biznetusa.com/api/get-searchbyapartment");
        setAppartements(response.data.apartment_by_city); // Set the cities from the 'apartment_by_city' array
      } catch (error) {
        console.error("Error fetching city data:", error);
      }
    };
    fetchCities();
  }, []);

  // Toggle between showing more or less
  const handleToggle = () => {
    setShowMore(!showMore);
  };

  // Split cities into two halves
  const firstHalf = Appartements.slice(0, 10); // First 10 cities
  const secondHalf = Appartements.slice(10); // Remaining cities

  return (
    <div className="container">
      <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 g-3">
        {/* First half (Always visible) */}
        {firstHalf.map((Appartements, index) => (
          <div className="col my-1" key={Appartements.id}>
            <Link to="/ProductMainDetail" className="text-dark text-decoration-none">
              {Appartements.title}
            </Link>
          </div>
        ))}

        {/* Second half (Initially hidden, shown when 'showMore' is true) */}
        {showMore && secondHalf.map((Appartements, index) => (
          <div className="col my-1" key={Appartements.id}>
            <Link to="/ProductMainDetail" className="text-dark text-decoration-none">
              {Appartements.title}
            </Link>
          </div>
        ))}
      </div>

      {/* Show more/less button */}
      <button className="btn show_more_layoutSet mt-4" onClick={handleToggle}>
        {showMore ? "Show less" : "Show more"}
      </button>
    </div>
  );
};

export default AppartementsRealEstate;
