import React from "react";
import "./TransactionCordinator.css";
import LatestNews from "../../../components/LatestNews";
import FinancialResults from "../../../components/FinancialResults";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import BiznetCorporation from "../../../components/BiznetCorporation";
import ComprehensiveRealEstateSolutions from "../../../components/ComprehensiveRealEstateSolutions";
import ContactForm from "../../../components/ContactForm";

const TransactionCordinator = () => {
  return (
    <>
      <Header />
      <div className="investor-page">
        <div className="investor-main d-flex justify-content-center align-items-center">
          <div className="container">
            <p>Tansaction Cordinator</p>
            <h1 className="w-100 fs-1">
              Transactions to prioritize the consumer's experience
            </h1>
            <p className="w-100">
              Biznet helps people buy &amp; sell homes in over 100 markets
              across the U.S. and Canada. As a residential real estate
              brokerage, we combine our agents to create faster, and affordable
              service.{" "}
            </p>
            <div className="btn">
              <button className=" btn-investor">View presentation</button>
            </div>
          </div>
        </div>
        <div className="second-section d-flex justify-content-between container flex-md-row flex-column my-5">
          <h1 className="h3 font-weight-bold mb-4">
            Streamlining transactions for a better consumer experience.
          </h1>
          <button className="btn-investor ">Get Started</button>
        </div>

        <LatestNews />
        <FinancialResults />
        <div className="container">
          <div className="row">
            <div className="d-flex justify-content-between align-items-center flex-md-row flex-column p-4 bg-light  rounded">
              <div className="flex-md-row flex-column d-flex">
                <h2 className="h5 fw-semibold">Latest annual filing</h2>
                <p className="text-muted">
                  For fiscal year ending December 31, 2023
                </p>
              </div>
              <button className="btn btn-investor  px-4 py-2">View 10-K</button>
            </div>
          </div>
        </div>
        <BiznetCorporation />
        <ComprehensiveRealEstateSolutions />
        <ContactForm />
      </div>
      <Footer />
    </>
  );
};

export default TransactionCordinator;
