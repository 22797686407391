import React from "react";
import img1 from "../../assets/images/blog 01.jpg"
import img2 from "../../assets/images/blog 02.jpg"

const FeaturedSection = () => {
    return (
        <div className="col-lg-8 col-md-12 featured">
            <h4>Featured</h4>
            <hr />
            <div className="card border-0">
                <img src={img1} alt="Featured Property" />
                <div className="card-body">
                    <h5 className="card-title">
                        Can You Negotiate Real Estate Commissions? Yes, and Here’s How
                    </h5>
                </div>
            </div>
            <div className="card border-0 mt-4">
                <img src={img2} alt="Featured Property" />
                <div className="card-body">
                    <h5 className="card-title">
                        Can You Negotiate Real Estate Commissions? Yes, and Here’s How
                    </h5>
                </div>
            </div>
        </div>
    );
};
export default FeaturedSection;