import React from 'react';

const ContactForm = () => {
  return (
    <section id="contact" className="py-5 bg-light">
      <div className="container">
        <h2 className="text-center mb-4">Get in Touch</h2>
        <form>
          <div className="row mb-3">
            <div className="col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="col-md-6">
              <input
                type="email"
                className="form-control"
                placeholder="Your Email"
                required
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <textarea
                className="form-control"
                placeholder="Your Message"
                rows={5}
                required
              />
            </div>
          </div>
          <div className="text-center">
            <button type="submit" className="btn btn-outline-info">
              Send Message
            </button>
          </div>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;
