import React from "react";

const PlanCard = ({
    title,
    iconUrl,
    price,
    subtitle,
    billingInfo,
    features,
    mostPopular,
    inquireNowText = "Inquire now",
    viewMoreText = "View More Details",
    popularBadgeText = "Most Popular",
}) => {
    return (
        <div className="col-lg-3 h-100 col-md-4 col-sm-6 mb-4">
            <div className={`card-plan bg-white rounded-lg p-4 ${mostPopular ? "position-relative popular-plan-card" : ""}`}>
                {mostPopular && (
                    <div className="most-popular-badge position-absolute top-0 end-0 bg-warning text-white small fw-bold px-2 py-1 rounded-start">
                        {popularBadgeText}
                    </div>
                )}
                <div className="plan-header d-flex align-items-center mb-3">
                    <img aria-hidden="true" alt={`${title}-icon`} src={iconUrl} className="plan-icon me-2" />
                    <h2 className="h5 plan-title mb-0">{title}</h2>
                </div>
                <p className="plan-subtitle text-muted">{subtitle}</p>
                <p className="plan-price h4 font-weight-bold">{price}</p>
                <p className="plan-billing text-muted">{billingInfo}</p>
                <h3 className="plan-features-title mt-3">What's included</h3>
                <ul className="plan-features list-unstyled">
                    {features.map((feature, index) => (
                        <li key={index}>
                            <i className="fa-solid fa-circle-check" /> {feature}
                        </li>
                    ))}
                </ul>
                <button className="btn btn-inquire w-100 mt-3">{inquireNowText}</button>
                <button className="btn btn-inquire w-100 mt-3">{viewMoreText}</button>
            </div>
        </div>
    );
};

export default PlanCard;
