import React from "react";
import img1 from "../../../assets/images/offer.png";
import UserHeader from "../../../components/UserHeader";
import Header from "../../../components/header";
import Footer from "../../../components/Footer";
import { Link } from "react-router-dom";

const Offer = () => {
  return (
    <>
      <Header />
      <UserHeader />
      <div id="main-content">
        <div className="parent-user-offer">
          <div className="container container-user-offer">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-10 col-12">
                <div className="offer-placeholder pt-5">
                  {/* Offer Icon */}
                  <img src={img1} alt="Offer Icon" />
                  {/* Heading */}
                  <h5>You don't have any offers, currently.</h5>
                  {/* Subtext */}
                  <p>
                    Get started on an offer day or night, seven days a week.
                  </p>
                  {/* Call to Action */}
                  <Link to="/start-an-offer">Start an offer</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default Offer;
